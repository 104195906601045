.m-cart.m-cart--empty .m-grid {
	display: block;
	width: 60%;
	margin: 0 auto;
	background-color: #fff;
	border-radius: 3px;
	filter: drop-shadow(0 1rem 1rem rgba(0,0,0,0.1));
	padding: 3rem;
}
.m-emptycart {
	&__message {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: 3rem;
	}
}
.m-cart .m-grid {
	width: 100%;
	grid-template-columns: 1fr;
	grid-template-areas:
		"carthead carthead"
		"cartsummary carttotals";
	padding: 0;
	align-items: start;

	@include media('>=tablet-lg') {
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-column-gap: 4rem;
	}

	h1 {
		grid-area: carthead;
		margin-bottom: 3rem;
	}
}
.m-cart {
	&__insurance {
		width: 100%;
		padding: 1rem 2rem;
		border-bottom: 1px solid $c-gray-xl;
		background: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		margin-bottom: 1.5rem;

		@at-root {
			.m-cart[cart-has-insurance] {
				.m-cart__insurance div::before {
					background-color: $c-brand-blue;
				}
			}
		}

		div {
			&::before {
				content: '';
				width: 4rem;
				height: 2.4rem;
				background-color: $c-gray-xxl;
				border-radius: 10rem;
				display: block;
				position: absolute;
				top: 50%;
				left: 18px;
				transform: translateY(-50%);
				cursor: default;
				transition: background-color 100ms ease-in-out;
			}
		}

		span {
			margin-left: 3rem;
		}

		input {
			appearance: none;
			display: block;
			width: 1.5rem;
			height: 1.5rem;
			padding: 0;
			aspect-ratio: 1/1;
			background: $c-gray-l;
			border-radius: 100%;
			border: 0;
			position: relative;
			z-index: 1;
			cursor: pointer;
			transition: background-color 200ms ease-in-out,
				transform 200ms cubic-bezier(0.75, 0, 0.25, 1);

			&:checked {
				background-color: #fff;
				transform: translateX(1.4rem);
			}
		}
	}
}
.m-totals {
	$root: &;
	grid-area: cartsummary;
	background-color: #fff;
	border-radius: 3px;
	filter: drop-shadow(0 1rem 1rem rgba(0,0,0,0.1));
	padding: 2rem;

	@include media('<tablet-lg') {
		margin-bottom: 3rem;
	}
	@include media('>=tablet-sm') {
		padding: 3rem;
	}

	header ul,
	section li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	header {
		padding-bottom: 1rem;
		margin-bottom: 2rem;
		border-bottom: 1px solid $c-gray-xxl;
		color: $c-gray-d;

		@include media('<tablet-sm') {
			display: none;
		}
	}
	section li {
		@include media('<=phone-md') {
			padding-bottom: 3rem;
		}

		&.no-click {
			p {
				margin-bottom: 0;
				color: $c-brand-blue-l;
				font-size: 1.6rem;
				display: block;
				width: 100%;
				font-style: italic;
			}
			.m-totals__price {
				margin-top: 1rem;
				text-align: left;
			}
		}
	}
	section li:not(:first-of-type) {
		margin-top: 3rem;
		padding-top: 3rem;
		border-top: 1px solid $c-gray-xxl;
	}
	header li {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& > footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 1rem 2rem;
		background-color: $c-gray-xxl;
		margin-top: 3rem;
		flex-wrap: wrap;
	}

	&__product {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include media('<=tablet-sm') {
			width: 57%;
		}

		@include media('<=phone-md') {
			justify-content: center;
			flex-wrap: wrap;
			width: 100%;
		}

		dl {
			display: flex;
			width: 100%;
			justify-content: flex-start;
			dd {
				font-weight: 600;
			}
		}

		&\:image {
			width: 45%;

			@include media('>=tablet-sm') {
				width: 30%;
			}

			@include media('<=phone-md') {
				width: 50%;
			}
		}
		&\:data {
			width: 45%;

			@include media('>=tablet-sm') {
				width: 66%;
			}
			@include media('<=phone-md') {
				width: 100%;
			}

			h3 {
				font-size: 2rem;

				@include media('>=tablet-sm') {
					font-size: 2.6rem;
				}

				a {
					color: $c-brand-blue;
					line-height: 1;
					display: block;
				}
			}
			a.remove {
				display: block;
				font-weight: bold;
				font-size: 1.4rem;
				text-transform: uppercase;
				margin-top: 1rem;
			}
		}
	}
	&__price,
	&__quantity,
	&__autoship,
	cart-item-update {
		width: 100%;

		@include media('<tablet-sm') {
			padding: 1rem 0;
		}

		@include media('>=tablet-sm') {
			width: 20%;
		}
	}
	&__autoshipnote {
		display: block;
		width: 100%;
		padding: 0.5rem 1rem;
		border-radius: 3rem;
		text-align: center;
		background-color: $c-brand-blue-m;
		text-transform: uppercase;
		margin-top: 1rem;
		font-size: 1.2rem;
		font-weight: 600;
		color: #fff;
	}
	&__autoship .m-select {
		padding-top: 0.7rem;
		padding-bottom: 0.7rem;
		border-radius: 3px;
	}
	&__price {
		text-align: center;
	}
	section #{$root}__price {
		font-size: 2rem;
	}
}
.m-cart__extras {
	grid-area: carttotals;
}
.m-gotocheckout {
	background-color: #fff;
	border-radius: 3px;
	filter: drop-shadow(0 1rem 1rem rgba(0,0,0,0.1));
	padding: 2rem;
	margin-bottom: 3rem;

	@include media('>=tablet-sm') {
		padding: 3rem;
	}

	&__title {
		display: block;
		width: 100%;
		font-size: 2.2rem;
		font-family: $font-b;
		color: $c-brand-blue;
		font-weight: 600;
		padding-bottom: 1rem;
		margin-bottom: 2rem;
		border-bottom: 1px solid $c-gray-xl;
	}

	&__subtotal,
	&__tax,
	&__coupons,
	&__shipping,
	&__summary {
		display: block;
		width: 100%;
		line-height: 1.6;
		font-size: 1.6rem;
		margin-bottom: 0.5rem;

		&.hide {
			display: none;
		}
	}

	.m-button {
		margin-top: 2rem;
	}
}
cart-item-update {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	footer {
		opacity: 0;
		pointer-events: none;
		transition: opacity 250ms ease-in-out;
		position: relative;
		width: 100%;
	}

	footer.active {
		opacity: 1;
		pointer-events: all;
	}

	.spinner {
		position: absolute;
		top: 1.5rem;
		right: -4rem;
		opacity: 1;
		pointer-events: all;
		transition: opacity 250ms ease-in-out;

		&.hide {
			opacity: 0;
			pointer-events: none;
		}
	}
}
.m-updatecartitem {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	position: relative;

	&__qty {
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		width: 100%;

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $c-gray-xxl;
			border: 1px solid $c-gray-xl;
			font-size: 1.8rem;
			font-weight: 600;
			cursor: pointer;
			width: 3rem;
			outline: 0;
			margin: 0;

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}

		&\:decrement {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		input {
			display: block;
			width: 6rem !important;
			font-size: 2rem !important;
			border-left: 0 !important;
			border-right: 0 !important;
			border-top: 1px solid $c-gray-xl !important;
			border-bottom: 1px solid $c-gray-xl !important;
			border-radius: 0 !important;
			color: #000;
			padding: 0.5rem;
			text-align: center;
			font-weight: 600;
			transition: border-color 250ms ease-in-out;
			margin: 0 !important;

			&.invalid {
				border-color: $c-brand-red;
			}
		}
		&\:increment {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}

	input[type=submit] {
		display: block;
		width: 100%;
		position: absolute;
		left: 0;
		bottom: -5rem;
		@extend .m-button;
		@extend .m-button--small;
		@extend .m-button--orange;
	}
}
coupon-code {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: $c-brand-blue-m;
	padding: 5px 10px;
	border-radius: 2px;
	color: #fff;
	font-style: normal;
	font-size: 1.4rem;
	font-weight: 600;
	margin: 5px;

	input[type=submit] {
		padding: 0;
		background-color: transparent;
		font-weight: 600;
		margin-left: 5px;
		color: #fff;
		border: 0;
		cursor: pointer;
	}
}
.m-checkout .m-grid {
	width: 100%;
	display: grid;
	grid-column-gap: 0;
	grid-template-areas:
		"checkoutnav"
		"checkoutnav"
		"checkoutsummary"
		"checkoutflow"
		"footcheckoutnav"
		"footcheckoutnav";
	padding: 0;

	@include media('>=tablet-lg') {
		grid-template-columns: 2fr 1fr;
		grid-column-gap: 4rem;
		grid-template-areas:
		"checkoutnav checkoutnav"
		"checkoutflow checkoutsummary"
		"footcheckoutnav footcheckoutnav";
	}
}
.m-summarycart + .m-checkout__nav {
	grid-area: footcheckoutnav;
	margin-top: 5rem;
	margin-bottom: 1rem;

	@include media('<phone-md') {
		font-size: 1.2rem;
	}
}
.m-loginbar {
	display: block;
	width: 100%;
	background-color: $c-brand-blue;
	padding: 1rem 1.5rem;
	border-radius: 1rem;
	color: #fff;

	a {
		color: #fff;
		font-weight: 600;
		display: inline-flex;
		justify-content: center;
		flex-wrap: wrap;

		span {
			width: 100%;
			display: block;
		}

		&::after {
			content: '';
			display: block;
			height: 1px;
			background-color: #fff;
			-webkit-transition: width 250ms ease-in-out;
			-o-transition: width 250ms ease-in-out;
			transition: width 250ms ease-in-out;
			width: 0.1%;
		}

		&:hover::after {
			width: 100%;
		}
	}
}
.m-checkout {
	&__nav {
		grid-area: checkoutnav;
		margin-bottom: 4rem;
   		margin-top: 2rem;
   		padding: 0 3.3rem;

		@include media('<phone-md') {
			font-size: 1.2rem;
		}

		& > div {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;

			@include media('<=phone-sm') {
				flex-wrap: wrap;
			}
		}

		@include media('<=phone-sm') {
			span {
				display: none !important;
			}
		}

		a {
			margin: 0 1rem;
			white-space: nowrap;

			@include media('<=phone-sm') {
				width: 100%;
				margin: 0.3rem 0;
			}

			&:first-of-type {
				margin-left: 0;
			}

			&:last-of-type {
				margin-left: 1rem;

				@include media('<=phone-sm') {
					margin-left: 0;
				}
			}

			&.active {
				font-weight: bold;
			}
		}

		.hide {
			display: none;
		}

		.only-with-shipping {
			display: flex;
			align-items: center;
			// width: 100%;
			white-space: nowrap;
		}
	}
	&__left {
		grid-area: checkoutflow;
	}
	&__promo {
		background-color: #fff;
		border-radius: 3px;
		filter: drop-shadow(0 1rem 1rem rgba(0,0,0,0.1));
		margin-bottom: 4rem;
		overflow: hidden;

		&__message {
			padding: 1rem 2rem 3rem;

			@include media('>=tablet-sm') {
				padding: 2rem 3rem 4rem;
			}
		}
	}
	&__form {
		background-color: #fff;
		border-radius: 3px;
		filter: drop-shadow(0 1rem 1rem rgba(0,0,0,0.1));
		padding: 2rem;
		@include form-styles;
		position: relative;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba(255,255,255,0.85);
			opacity: 0;
			transition: opacity 250ms ease-in-out;
			position: absolute;
			z-index: 5;
			pointer-events: none;
			top: 0;
			left: 0;
		}
		&::after {
			content: '';
			@extend .spinner;
			position: absolute;
			z-index: 6;
			top: calc(50% - 1.5rem);
			left: calc(50% - 1.5rem);
			opacity: 0;
			transition: opacity 250ms ease-in-out;
			pointer-events: none;
		}

		.woocommerce-NoticeGroup-checkout {
			display: none !important;
		}

		&.loading {
			&::before,
			&::after {
				opacity: 1;
				pointer-events: all;
			}
		}

		@include media('>=tablet-sm') {
			padding: 3rem;
		}

		.hide {
			display: none;
		}

		.l-col {
			margin-bottom: 0 !important;
		}

		.l-col--2 {
			&:first-of-type {
				@include media('>=tablet-sm') {
					padding-right: 2rem;
				}
			}
			&:last-of-type {
				@include media('>=tablet-sm') {
					padding-left: 2rem;
				}
			}
		}

		&--one-step {
			.m-checkout__step {
				display: block !important;
				opacity: 1;

				&:not(:last-child) {
					.m-checkout__actions {
						display: none;
					}
				}

				&:last-child {
					.m-checkout__actions {
				    	justify-content: flex-end;
				    }
				}
			}
			.m-checkout__actions {
				//display: none;
				a.m-button {
					display: none;
				}
			}
		}
	}

	&__step {
		display: none;
		pointer-events: 0;
		opacity: 0;
		transition: opacity 250ms ease-in-out;

		&.active {
			display: block;
			opacity: 1;
			pointer-events: all;
		}

		p {
			margin-bottom: 2rem;
		}
	}

	&__actions {
		@include media('<tablet-sm') {
			flex-direction: column-reverse;
		}

		a, input {
			@include media('<tablet-sm') {
				margin-bottom: 3rem;
			}
		}
	}
}
.sub_address_fields {
	display: block;
	position: relative;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(255,255,255,0.9);
		opacity: 0;
		transition: opacity 250ms ease-in-out;
		position: absolute;
		z-index: 5;
		pointer-events: none;
	}
	&::after {
		content: '';
		@extend .spinner;
		position: absolute;
		z-index: 6;
		top: calc(50% - 1.5rem);
		left: calc(50% - 1.5rem);
		opacity: 0;
		transition: opacity 250ms ease-in-out;
		pointer-events: none;
	}

	&.loading {
		&::before,
		&::after {
			opacity: 1;
			pointer-events: all;
		}
	}
}
#shippingaddress .m-addressfields__address {
	margin-top: 3rem;
}
#shippingaddress > .m-checkbox {
	padding-bottom: 1.5rem;
	margin-bottom: 3rem;
	border-bottom: 1px solid $c-gray-xl;
}
.m-addressfields__title,
.m-delivery__title,
.m-survey__title,
.m-payment__title {
	font-size: 3.6rem;
	margin-bottom: 3rem;
}
.m-delivery__note,
.m-survey__note,
.m-payment__note {
	display: block;
	margin-bottom: 1rem;
}
.m-createaccount {
	background-color: $c-gray-xxl;
	padding: 2rem 3rem;
	margin-bottom: 2rem;

	input {
		margin-bottom: 0 !important;
		display: none;

		&.active {
			display: block;
		}
	}
}
.woocommerce-password-strength {
	margin-top: 2rem;
	margin-bottom: 1rem;
	font-weight: 600;
	color: $c-func-success;

	&.short,
	&.bad {
		color: $c-brand-red;
	}
	&.good {
		color: $c-func-warning;
	}
}
.m-payment__options,
.m-delivery__options {
	display: block;
	width: 100%;
	border-radius: 3px;
	margin-bottom: 3rem;

	@include media('>=tablet-sm') {
		border: 1px solid $c-gray-xxl;
	}

	li {
		display: block;
		padding: 2rem 0;
		margin: 0;

		@include media('>=tablet-sm') {
			padding: 2rem 2.5rem;
		}

		&:not(:first-of-type) {
			border-top: 1px solid $c-gray-xxl;
		}
	}

	&--none {
		font-size: 1.4rem;
		background-color: $c-gray-xxl;
		padding: 0.5rem 1rem;
		margin: 0.5rem;
		display: block;
		width: calc(100% - 1rem);
	}
}
.m-delivery__option {
	label {
		display: flex !important;
		align-items: center;
		justify-content: space-between;
		margin: 0 !important;
	}
}
.m-payment__option {
	& > label {
		display: flex !important;
		align-items: center;
		justify-content: space-between;

		@include media('<tablet-sm') {
			flex-direction: column;
		}
	}

	span.title {
		@include media('<tablet-sm') {
			line-height: 1.4 !important;
			width: 100%;
		}
	}
}
.m-gateway__fields {
	& > p {
		display: block;
		background-color: $c-gray-xxl;
		padding: 1rem;
		margin-bottom: 2rem;

		&:first-child {
			margin-top: 2rem;
		}
	}
	.woocommerce-paytrace-SavedPaymentMethods-wrapper {
		background-color: $c-gray-xxl;
		padding: 0;
		margin-bottom: 2rem;
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;

		input[type=radio] {
			display: initial;
		}

		ul {
			width: calc(100% - 5rem);
			margin: 2rem 2.5rem;
		}

		li {
			padding: 0;
			border: 0;
		}
		div.clear {
			display: none;
		}
	}
	.paytrace-create-account {
		margin: 0;
		width: 100%;
	}
	.paytrace_manage_tokens {
	    white-space: nowrap;
	    margin-top: 2rem;
	    margin-right: 2.5rem;
	}
	fieldset {
		display: block;

		@include media('>=tablet-sm') {
			background-color: #fff;
			padding: 0;
		}

		& > div {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-wrap: wrap;

			@include media('>=tablet-sm') {
				margin: 2rem 0;
				width: calc(100% - 6rem);
				margin-left: 3rem;
			}

			&.clear {
				display: none;
			}
		}

		.form-row {
			display: inline-block;
			padding: 0;
		}
	}

	input {
		margin-bottom: 0rem !important;
	}

	input[type=radio] + label,
	input[type=checkbox] + label {
		&::before {
			top: 0 !important;
		}
		&::after {
			top: 4px !important;
		}
	}

	.button {
		@extend .m-button;
		@extend .m-button--orange;
	}

	.form-row:not(:last-of-type) {
		margin-bottom: 3rem;
	}

	.form-row {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;

		label {
			width: 100%;
			//margin-top: 1rem !important;
			margin-bottom: 1rem !important;
		}
	}

	.form-row-wide {
		width: 100%;
	}

	.form-row-first,
	.form-row-last {
		width: 50%;
		
		@include media('<=phone-md') {
			width: 100%;
		}

		input {
			margin-bottom: 0 !important;
		}
	}
	.form-row-first {
		@include media('>=phone-md') {
			padding-right: 2rem !important;
		}
	}
	.form-row-last {
		@include media('>=phone-md') {
			padding-left: 2rem !important;
		}
	}
}
.m-cccards {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@include media('<tablet-sm') {
		width: 100%;
		justify-content: flex-start;
	}
}
.m-cccard {
	margin-left: 1rem !important;
	display: block !important;
	width: 2.5rem;
	height: 2.5rem;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	font-size: 0;
	padding: 0 !important;
	border: 0 !important;

	&:first-of-type {
		@include media('<tablet-sm') {
			margin-left: 0 !important;
		}
	}

	@include media('>=phone-md') {
		width: 3.5rem;
	}

	@include media('>=tablet-sm') {
		width: 4.5rem;
		background-size: auto 100%;
	}

	&--VISA,
	&--visa {
		background-image: url(../images/visa.svg);
	}

	&--MC,
	&--mc {
		background-image: url(../images/mastercard.svg);
	}

	&--AMEX,
	&--amex {
		background-image: url(../images/amex.svg);
	}

	&--DISCOVER,
	&--discover,
	&--DISC,
	&--disc {
		background-image: url(../images/discover.svg);
	}

	&--DINERS,
	&--diners {
		background-image: url(../images/diners.svg);
	}

	&--JCB,
	&--jcb {
		background-image: url(../images/jcb.svg);
	}
}
.m-summarycart {
	grid-area: checkoutsummary;
	margin-bottom: 2rem;
}
.m-cartsummary {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	background-color: #fff;
	border-radius: 3px;
	filter: drop-shadow(0 1rem 1rem rgba(0,0,0,0.1));
	padding: 2rem;
	margin-bottom: 2rem;
	position: relative;

	@include media('>=tablet-sm') {
		padding: 3rem;
		margin-bottom: 4rem;
	}

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(255,255,255,0.85);
		opacity: 0;
		transition: opacity 250ms ease-in-out;
		position: absolute;
		z-index: 5;
		pointer-events: none;
		top: 0;
		left: 0;
	}
	&::after {
		content: '';
		@extend .spinner;
		position: absolute;
		z-index: 6;
		top: calc(50% - 1.5rem);
		left: calc(50% - 1.5rem);
		opacity: 0;
		transition: opacity 250ms ease-in-out;
		pointer-events: none;
	}

	&.loading {
		&::before,
		&::after {
			opacity: 1;
			pointer-events: all;
		}
	}

	&__items {
		display: block;
		width: 100%;
	}
	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 2rem;
		padding-bottom: 2rem;
		border-bottom: 1px solid $c-gray-xxl;
		width: 100%;
	}
	&__price {
		display: block;
		margin-top: 0.5rem;
	}
	&__autoship {
		display: block;
		width: 100%;
		padding: 0.5rem 1rem;
		border-radius: 3rem;
		text-align: center;
		background-color: $c-brand-blue-m;
		text-transform: uppercase;
		margin-top: 1rem;
		font-size: 1.2rem;
		font-weight: 600;
		color: #fff;
	}
	&__product {
		width: 30%;

		&\:name {
			font-size: 2rem;
		}
		&\:desc {
			color: $c-brand-blue-l;
			font-size: 1.6rem;
			display: block;
			width: 100%;
			margin-bottom: 1rem;
			font-style: italic;
		}
	}
	&__product\:data {
		width: 65%;

		dl {
			display: flex;
			width: 100%;
			justify-content: flex-start;
			dd {
				font-weight: 600;
			}
		}
	}
	&__totals {
		display: block;
		width: 100%;

		li:not(:last-of-type) {
			margin-bottom: 1rem;
		}

		dl {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 1.6rem;
		}
		dd {
			font-weight: 600;
		}

		.hide {
			display: none;
		}

		.total {
			margin-top: 2rem;
			padding-top: 2rem;
			border-top: 1px solid $c-gray-xl;

			dt {
				font-size: 1.8rem;
			}
			dd {
				font-size: 2.4rem;
			}
		}
	}
}
.m-coupon {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	border-radius: 3px;
	filter: drop-shadow(0 1rem 1rem rgba(0,0,0,0.1));
	padding: 2rem;
	@include form-styles;

	@include media('<phone-md') {
		flex-direction: column;
	}

	@include media('>=tablet-sm') {
		padding: 3rem;
	}

	.entry {
		width: calc(100% - 10rem);

		@include media('<phone-md') {
			width: 100%;
			margin-bottom: 1rem;
		}

		input {
			margin-bottom: 0 !important;
		}
	}
	input[type="submit"] {
		width: 8rem !important;
		@extend .m-button;
		@extend .m-button--orange;

		@include media('<phone-md') {
			width: 100% !important;
		}
	}
}
.m-confirmation,
.m-orderpay {
	display: block;

	header,
	footer {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
	}
	footer .l-row {
		align-items: stretch;

		@include media('<=tablet-sm') {
			.l-col--3\.5 {
				width: 100%;
			}
		}
	}

	&__status {
		display: block;
		width: 100%;
		text-align: center;
		margin-top: 3rem;

		b {
			text-transform: uppercase;
			background-color: $c-brand-blue-m;
			color: #fff;
			padding: 0.5rem;
		}

		&--processing b {
			background-color: $c-brand-orange;
		}

		&--on-hold b {
			background-color: $c-brand-orange;
		}
		&--failed b,
		&--pending-payment b {
			background-color: $c-brand-red;
		}
	}

	&__overview {
		display: flex;
		align-items: stretch;
		justify-content: center;
		margin: 2rem auto 6rem;
		border: 1px solid $c-gray-xl;
		border-radius: 3px;
		background-color: #fff;

		@include media('<tablet-sm') {
			flex-direction: column;
			width: 100%;
			padding: 1rem 0;
		}

		dl {
			padding: .7rem 2rem;

			@include media('>=tablet-sm') {
				padding: 2rem;
			}

			&:not(:last-of-type) {
				@include media('>=tablet-sm') {
					border-right: 1px solid $c-gray-xl;
				}
			}

			dt {
				font-weight: 600;
				text-transform: uppercase;
			}
		}
	}

	&__details {
		display: block;
		background-color: #fff;

		@include media('<tablet-sm') {
			width: 100%;
			margin-bottom: 3rem;
		}

		@include media('>=tablet-sm') {
			margin-bottom: 6rem;
		}

		h2 {
			font-size: 8rem;
			text-align: center;
			margin-top: 2rem;
			margin-bottom: 1rem;
			font-family: $font-s;
		}

		li {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			border-top: 1px solid $c-gray-xl;
			border-left: 1px solid $c-gray-xl;
			border-right: 1px solid $c-gray-xl;

			&:first-of-type {
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;
			}

			&:last-of-type {
				border-bottom: 1px solid $c-gray-xl;
				border-bottom-left-radius: 3px;
				border-bottom-right-radius: 3px;
			}
		}

		&\:product {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 2.5rem;

			@include media('<=phone-md') {
				flex-wrap: wrap;
				justify-content: center;
			}

			picture, img {
				width: 20%;

				@include media('<=phone-md') {
					width: 50%;
				}
			}

			picture img {
				width: 100%;
			}

			&__data {
				width: 80%;
				padding-left: 2rem;

				@include media('<=phone-md') {
					width: 100%;
					padding: 0;
				}

				h3 {
					display: block;
					width: 100%;
					font-size: 2.6rem;
					margin-bottom: 1rem;

					@include media('>=tablet-sm') {
						font-size: 2.8rem;
					}
				}
			}
			&__price {
				display: block;
				width: 100%;
				font-size: 1.8rem;
				font-weight: 600;
			}
			&__autoship {
				display: block;
				width: 100%;
			}
		}

		li:not(.product) {
			justify-content: center;
			background-color: $c-gray-xxl;

			@include media('<tablet-sm') {
				flex-direction: column;
				padding: 1rem 0;
			}

			dl {
				width: 100%;
				padding: .7rem 2rem;

				@include media('>=tablet-sm') {
					width: 25%;
					padding: 2rem;
				}

				&:not(:last-of-type) {
					@include media('>=tablet-sm') {
						border-right: 1px solid $c-gray-xl;
					}
				}

				dt {
					font-weight: 600;
					text-transform: uppercase;
				}
			}
		}
	}

}
.m-address {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	border: 1px solid $c-gray-xl;
	padding: 1.5rem;
	background-color: #fff;

	&:nth-child(1) {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;

		@include media('<tablet-sm') {
			border-radius: 3px;
		}
	}
	&:nth-child(2) {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;

		@include media('>=tablet-sm') {
			border-left: 0;
		}

		@include media('<tablet-sm') {
			border-radius: 3px;
		}
	}

	h2 {
		width: 100%;
		margin-bottom: 1rem;
		font-size: 6rem;
		font-family: $font-s;
	}

	address {
		width: 100%;
	}
	span {
		display: block;
		width: 100%;
	}
}
.m-paynowfooter {
	margin-top: 3rem;
}
#order_review {
	& > span {
		color: $c-brand-blue;
		font-size: 6rem;
		margin-bottom: 1rem;
		font-family: $font-s;
	}
}
.m-sitemap {
	display: block;
	width: 100%;

	&__section {
		display: block;
		width: 100%;

		h3 {
			font-size: 1.8rem;
			font-weight: 600;
			margin: 2rem 0 1rem;
		}

		ul {
			padding-left: 3rem;
			margin-bottom: 1rem;
		}
	}
}
.m-search {
	h1 {
		display: block;
		width: 100%;
		text-align: center;
		line-height: 1.1;
		margin: 3rem auto;
	}

	.empty {
		display: block;
		padding: 2rem 0;
		border-top: 1px solid $c-gray-xl;
		border-bottom: 1px solid $c-gray-xl;
		margin: 3rem 0;
		width: 100%;
		text-align: center;
		text-transform: uppercase;
	}
}
.m-results {
	width: 100%;
	padding: 6rem 0;
	&:not(:last-of-type) {
		border-bottom: 1px solid $c-gray-xxl;
	}

	h2 {
		font-size: 2.4rem;
		text-transform: uppercase;
		color: $c-brand-orange;
		text-align: center;
		font-weight: 600;
		margin-bottom: 2rem;
	}

	.m-pagegrid {
		mini-post {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.m-minipost__content {
			margin-top: auto;
		}
	}

	.m-postgrid,
	.m-pagegrid {
		@include media('>=phone-lg', '<tablet-lg') {
			grid-template-columns: 1fr;
			@include msgrid(60, 1, 2rem, 2rem);
		}
	}
}