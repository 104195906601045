
/***********************/
body {
	.m-header {
		transition: all .3s ease-in-out;
		position: relative;

		&--pro {
			background: $c-brand-teal;

			.m-header__middle {
				border: 0;
			}

			.m-header__phone {
				color: #fff;

				a {
					color: #fff;
				}

				svg {
					path {
						fill: #000;
					}
				}
			}

			.m-header-actions {
				a {
					color: #fff;

					svg {
						path {
							fill: #000;
						}
					}
				}
			}

			.m-minicart__icon {
				span {
					background: #000;
				}
			}
		}

		&__notice {
			@include gradient-blue;
			color: #fff;
			font-size: 1.4rem;
			font-weight: 700;
			text-align: center;
			padding: 1rem;

			a {
				text-decoration: underline;
				color: #fff;
			}
		}

		&__phone {
			color: $c-brand-orange;
			justify-content: flex-start;
			flex-wrap: wrap;

			& > div {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}

			.m-button {
				font-size: 1.4rem;
				padding: 1rem 1.6rem;
				margin-top: -1.5rem;

				@include media('<tablet-md') {
					margin-top: 0;
				}

				@include media('<phone-lg') {
					display: none;
				}
			}

			span {
				@include media('<tablet-md') {
					font-size: 0;
				}
			}

			a {
				font-weight: 700;
				color: $c-brand-orange;
				display: inline-flex;
				align-items: center;

				@include media('<tablet-md') {
					letter-spacing: -.1rem;
					color: $c-brand-blue;
					font-weight: 400;
				}

				@include media('<phone-lg') {
					font-size: 0;
				}
			}

			svg {
				width: 1.8rem;
			    height: 3rem;
			    margin: 0 .4rem 0 .7rem;

				@include media('<tablet-md') {
					width: 2rem;
	    			height: 2.5rem;
	    			margin: 0;
	    		}

				path {
					fill: $c-brand-orange;

					@include media('<tablet-md') {
						fill: $c-brand-blue;
					}
				}
			}
		}

		&__middle {
			border-bottom: 1px solid $c-gray-xxl;

			&__side {
				@include media('>=tablet-sm') {
					width: calc(50% - 10.5rem);
				}
			}
			&__center {
				width: 21rem;
			}

			.l-col {
				margin-bottom: 0 !important;

				&:nth-child(1) {
					@include media('<tablet-sm') {
						width: 20rem !important;
					}

					@include media('<phone-lg') {
						order: 2;
						width: 3rem !important;
						margin-right: auto;
					}

					@media screen and (max-width: 360px) {
						display: none;
					}
				}
				&:nth-child(2) {
					@include media('<tablet-sm') {
						width: calc(100% - 40rem) !important;
					}
					@include media('<phone-lg') {
						order: 1;
						width: calc(100% - 16rem) !important;
						justify-content: flex-start;
					}
					@include media('<=phone-md') {
						width: calc(100% - 20rem) !important;
					}
					@include media('>=phone-md', '<phone-lg') {
						width: calc(100% - 29rem) !important;
					}
				}
				&:nth-child(3) {
					@include media('<tablet-sm') {
						width: 20rem !important;
					}
					@include media('<phone-lg') {
						width: 11rem !important;
						order: 3;
					}
				}
			}
		}

		&__bottom {
			border-bottom: 1px solid $c-gray-xxl;
		}

		&.mobile-nav-active {
			@include media('<mobile-nav') {
				background: #fff;
			}
		}

		.l-wrapper {
			padding-bottom: 2rem;

			@include media('<tablet-md') {
				padding: .5rem 2rem .7rem 2rem;
			}
		}
		.m-brand {

			&__mark {
				display: block;
				max-width: 13.2rem;
    			padding-top: 7.5rem;
				background-repeat: no-repeat;
				background-size: 100% auto;
				background-position: center;
				font-size: 0;

				@include media('>=tablet-sm') {
					max-width: 17rem;
					padding-top: 45%;
				}

				@include media('>=tablet-lg') {
					max-width: 20rem;
				}

				@include media('>=phone-lg') {
					margin: 0 auto;
				}
			}
		}
	}

	&.m-header-display--simple {
		.m-header {
			margin-bottom: 0;
			
			@include media('<tablet-sm') {
				margin-bottom: 0;
			}

			.l-wrapper {
				border: 0;
			}
		}
	}
}
a[href="#globalsearch"] * {
	pointer-events: none;
}
.m-gsearch {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.7);
	opacity: 0;
	pointer-events: none;
	transition: opacity 250ms ease-in-out;

	&.active {
		opacity: 1;
		pointer-events: all;
	}

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 4rem;
		left: 50%;
		transform: translateX(-50%);
		width: 6rem;
		height: 6rem;
		font-size: 3.6rem;
		color: #fff;
		background-color: $c-brand-blue;
		border: 0;
		border-radius: 100%;
		outline: none;
		cursor: pointer;
	}

	form {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	input {
		display: block;
		width: 80%;
		border: 0;
		background-color: #fff;
		padding: 3rem 4rem;
		font-size: 2.4rem;

		@include media('<=phone-md') {
			width: 90%;
			padding: 2rem 3rem;
			font-size: 1.8rem;
			margin-bottom: 25rem;
		}
	}
}
.m-responsive-navs {
	display: none;
	
	@include media('>=mobile-nav') {
		display: none !important;
	}
}
.m-popup {
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	display: none;
	width: 100%;
	height: 100%;
	transition: opacity 250ms ease-in-out;
	pointer-events: none;
	opacity: 0;
	background-color: rgba(#000, .91);
	align-items: center;
	justify-content: center;
}
.m-preheader {
	display: block;
	width: 100%;
}
.menu-pre-header-menu-container .menu {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: -2.5rem;
	position: relative;
	z-index: 10;

	@include media('<phone-md') {
		justify-content: center;
		padding-bottom: 2rem;
	}

	li {
		&:not(:last-of-type) {
			margin-right: 3rem;
		}

		&.hide-mobile {
			@include media('<tablet-sm') {
				display: none;
			}
		}

		&.special a {
			font-style: italic;
			color: $c-brand-orange;
		}
	}

	a {
		color: $c-gray-d;
		font-size: 1.5rem;
		font-weight: 600;
	}
}
.m-header-nav {
	@include media('<mobile-nav') {
		display: none;
	}
}
.m-header-actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	a {
		display: flex;
		font-weight: 700;
		color: $c-brand-blue;
		line-height: 1.7;

		@include media('<tablet-md') {
			font-size: 0;
		}

		svg {
			margin-right: .7rem;

			path {
				fill: $c-brand-blue-l;

				@include media('<tablet-md') {
					fill: $c-brand-blue;
				}
			}
		}

		&:not(:last-child) {
			margin-right: 3.5rem;

			@include media('>=mobile-nav') {
				margin-right: 2rem;
			}
		}

		&.m-header-actions__search {
			@include media('<tablet-sm') {
				display: none;
			}
		}

		&.m-header-actions__account {
			@include media('<phone-md') {
				display: none;
			}
		}

		&.m-header-actions__wishlist {
			@include media('<phone-md') {
				display: none;
			}
		}
	}
}
.m-mainnav {

	&__list {
		display: flex;
		align-items: flex-start;
		justify-content: center;

		& > li {
			transition: background 250ms ease-in-out;

			&:hover, &.active {
				color: #fff;
				background: $c-brand-blue;

				> a, span {
					color: #fff;
				}

				.m-mainnav__submenu {
					opacity: 1;
					transition: opacity .2s ease-in-out;
					pointer-events: all;
					box-shadow: 0 2rem 2rem rgba(#000, .15);
				}
			}

			> a, span {
				font-weight: 700;
			}

			&.m-highlighted-link {
				> a, span {
					color: $c-brand-orange;
				}

				&:hover {
					background: $c-brand-orange;

					> a, span {
						color: #fff;
					}
				}
			}
		}
	}
	&__item {

		&.mobile-only,
		&.mobile-sm-only {
			@include media('>=tablet-sm') {
				display: none;
			}
		}

		&--has-megamenu {
			&.active {
				color: #fff;
				background: $c-brand-blue;

				.m-mega {
					opacity: 1;
					pointer-events: all;
					transform: translateY(0);
				}
			}
		}

		&--has-submenu {
			position: relative;

			&.active {
				color: #fff;
				background: $c-brand-blue;

				.m-mainnav__submenu {
					opacity: 1;
					pointer-events: all;
					transform: translateY(0);
					height: auto;
				}
			}
		}
	}
	&__link {
		display: inline-flex;
		font-size: 1.5rem;
		text-transform: uppercase;
		color: $c-brand-blue;
		padding: 1.5rem 1.3rem;
	}
	&__submenu {
		overflow: hidden;
		opacity: 0;
		pointer-events: none;
		background: #fff;
	}
	&__arrow {
		width: 1rem;
		height: 1.9rem;
		margin-left: .5rem;

		path {
			fill: $c-brand-blue-l;
		}
	}

}
.m-link {
	&--decorated {
		& > a {
			position: relative;
			&::after {
				content: '';
				width: 100%;
				height: 3px;
				background-color: $c-brand-blue-m;
				position: absolute;
				left: 0;
				bottom: -3px;
			}
		}
	}
}
.m-minicart {
	button[name="close"] {
		background: transparent;
		padding: 0;
		border: 0;
		border-radius: 0;
		cursor: pointer;
		outline: none;
		position: absolute;
		left: 2rem;
		top: 50%;
		transform: translateY(-50%);
		z-index: 99;

		svg {
			display: block;
			width: 2.2rem;
			height: auto;
			pointer-events: none;

			* {
				fill: $c-brand-blue;
			}
		}
	}
	&__header {
		position: relative;
	}
	&__icon {
		position: relative;

		@include media('<tablet-sm') {
			font-size: 0;
		}

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 2.2rem;
			height: 2.2rem;
			background-color: $c-brand-blue-l;
			border-radius: 100%;
			color: #fff;
			font-size: 1.4rem;
			font-weight: 600;
			transform: translateX(1rem) translateY(-50%);

			@include media('<tablet-md') {
				background-color: $c-brand-blue;
			}
		}
	}
}
.m-mainnav__submenu {
	border-top: .4rem solid $c-brand-blue;
	position: absolute;
	left: 0;
	width: 24rem;
	z-index: 999;
	pointer-events: none;
	opacity: 0;
	transform: translateY(-1px);
	transition: all 350ms ease-in-out;

	a {
		text-transform: none;
		font-size: 1.4rem;
		padding: 1rem 2rem;
		display: block;
		width: 100%;
		font-weight: 400;
		color: $c-brand-blue;

		&:hover {
			background: linear-gradient(-90deg, #FAFAFA, #FAFAFA 80%, #fff) !important;
		}
	}
}
.m-mega {
	$mega: &;
	display: flex;
	position: absolute;
	left: 0;
	width: 100%;
	z-index: 999;
	pointer-events: none;
	opacity: 0;
	transform: translateY(-1px);
	transition: all 350ms ease-in-out;

	.l-wrapper {
		display: grid;
		grid-column-gap: 5rem;
		padding: 3rem 3rem 5rem;
		position: relative;
		z-index: 2;
		align-items: stretch;
		background: #fff;
		border-top: .4rem solid $c-brand-blue;
		box-shadow: 0 2rem 2rem rgba(#000, .15);
	}
	&--1-col .l-wrapper {
		grid-template-columns: repeat(1, 1fr);
		@include msgrid(1, 1, 5rem, 2rem);
	}
	&--2-col .l-wrapper {
		grid-template-columns: repeat(2, 1fr);
		@include msgrid(2, 2, 5rem, 2rem);
	}
	&--3-col .l-wrapper {
		grid-template-columns: repeat(3, 1fr);
		@include msgrid(3, 3, 5rem, 2rem);
	}
	&--4-col .l-wrapper {
		grid-template-columns: repeat(4, 1fr);
		@include msgrid(4, 4, 5rem, 2rem);
	}
	&--5-col .l-wrapper {
		grid-template-columns: repeat(5, 1fr);
		@include msgrid(5, 5, 5rem, 2rem);
	}
	&--1-5-col .l-wrapper {
		grid-template-columns: 1fr 4fr;
	}
	&--5-1-col .l-wrapper {
		grid-template-columns: 4fr 1fr;
	}

	.l-col {
		&:not(:last-child) {
			border-right: .1rem solid $c-gray-l;
		}
		.l-col {
			border: 0;
		}
	}

	&\:simple_card {
		display: block;
		width: 100%;

		&__image {
			display: block;

			picture {
				display: block;
			}

			&:hover + a {
				color: $c-brand-blue-m;
			}
		}
		&__heading {
			display: block;
			width: 100%;
			font-size: 1.8rem;
			font-weight: 600;
			color: $c-gray-md;
			text-align: center;
			margin-top: 2rem;

			&:hover {
				color: $c-brand-blue-m;
			}
		}
	}
	&\:simple_nav {
		$root: &;
		display: block;
		width: 100%;

		a {
			display: block;
			width: 100%;
			font-size: 1.8rem;
			color: $c-gray-d;

			&:hover {
				color: $c-brand-blue-m;
			}

			&:not(:last-child) {
				margin-bottom: 3rem;
			}

			&#{$root}--secondary {
				font-size: 1.6rem;
				font-weight: 600;
				text-transform: uppercase;
				color: $c-brand-blue-m;
			}
		}
	}
	&\:heading {
		color: $c-gray-m;
		font-size: 1.1rem;
		font-weight: 700;
		letter-spacing: .1rem;
		text-transform: uppercase;
		padding: 0rem 2rem 1rem;

		&.active {
			background: none !important;
		}
	}
	&\:highlighted {
		a {
			color: $c-brand-orange !important;
		}
	}
	&\:submenu {
		a {
			text-transform: none;
			font-size: 1.4rem;
			padding: .8rem 2rem;
			display: block;
			width: 100%;
			font-weight: bold;
			color: $c-brand-blue;
		}
		&--categories {
			a {
				padding-top: .6rem;
				padding-bottom: .6rem;
			}
		}
		&--products {
			opacity: 0;
			height: 0;
			overflow: hidden;
			transition: opacity .3s ease-in-out;
			a {
				font-weight: 400;
			}

			&.active {
				opacity: 1;
				height: auto;
				overflow: visible;
			}
		}
		li {
			display: block;
			width: 100%;

			&.active {
				background: linear-gradient(-90deg, #FAFAFA, #FAFAFA 80%, #fff) !important;

				&.m-mega\:heading {
					background: none !important;
				}
			}

			+ li.m-mega\:heading {
				padding-top: 2rem;
			}
		}
	}
	&\:menu_image_set {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;

		#{$mega}\:menu_image {
			opacity: 0;
			pointer-events: none;
			transition: all 250ms ease-in-out;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;

			&.active {
				opacity: 1;
				pointer-events: all;
			}
		}

	}
	&\:menu_image {
		&,
		&--initial {
			a, defer-image, picture, img {
				display: block;
			}
			defer-image, picture, img {
				position: relative;
				z-index: 1;
			}
			a {
				position: relative;
				&::before {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					background: rgba(0,0,0,0.2);
					background: radial-gradient(ellipse at center, rgba(0,0,0,0.05) 0, rgba(0,0,0,0.18) 60%, rgba(0,0,0,0.3) 100%);
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
				}

				span {
					display: flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 3;
					width: 100%;
					height: 100%;
					font-size: 6.6rem;
					font-weight: 600;
					font-family: $font-h;
					color: #fff;
					text-shadow: 0 0 1rem rgba(0,0,0,0.3);
				}
			}
		}
	}
	&\:product_category_nav {
		.m-card {
			&__footer {
				justify-content: center;
			}
			.l-col--2 {
				star-reviews {
					justify-content: left;
				}
				.m-card__title, 
				.m-card__subtitle {
					text-align: left;
				}
				.m-card__footer {
					justify-content: left;
				}
			}
			&--stacked {
				flex-direction: column;
				justify-content: center;
				text-align: center;
				width: 100%;
				padding-bottom: 2rem;

				.m-card__header {
					img {
						max-height: 20rem;
    					width: auto;
					}
					.m-card__title,
					.m-card__subtitle {
						padding-left: 1rem;
						padding-right: 1rem;
					}
				}
			}

			.m-stars__reviews {
				display: none;
			}
		}
	}
}
.m-row,
.m-grid {
	position: relative;
	padding: 2rem 0;

	.l-wrapper {
		position: relative;
		z-index: 12;
	}
}
.single-product {
	.m-row,
	.m-grid {
		position: relative;
		padding: 0;
	}
}
.m-row {
	&.top {
		&,
		& > .l-wrapper {
			align-items: flex-start;
		}
	}
	&.middle {
		&,
		& > .l-wrapper {
			align-items: center;
		}
	}
	&.bottom {
		&,
		& > .l-wrapper {
			align-items: flex-end;
		}
	}
	&.stretch {
		&,
		& > .l-wrapper {
			align-items: stretch;
		}
	}

	&.left {
		&,
		& > .l-wrapper {
			justify-content: flex-start;
		}
	}
	&.center {
		&,
		& > .l-wrapper {
			justify-content: center;
		}
	}
	&.right {
		&,
		& > .l-wrapper {
			justify-content: flex-end;
		}
	}
}
.m-grid {
	&.top > .l-wrapper > .l-grid {
		align-items: start;
	}
	&.middle > .l-wrapper > .l-grid {
		align-items: center;
	}
	&.bottom > .l-wrapper > .l-grid {
		align-items: end;
	}
	&.stretch > .l-wrapper > .l-grid {
		align-items: stretch;
	}

	&.left > .l-wrapper > .l-grid {
		justify-content: start;
	}
	&.center > .l-wrapper > .l-grid {
		justify-content: center;
	}
	&.right > .l-wrapper > .l-grid {
		justify-content: end;
	}
	&.stretch > .l-wrapper > .l-grid {
		justify-content: stretch;
	}
}
.m-row--c-nopadding {
	& > .l-wrapper {
		padding-top: 0;
		padding-bottom: 0;
	}
}
.m-row--nopadding {
	padding-top: 0;
	padding-bottom: 0;
}
.m-row--space {
	& > .l-wrapper {
		align-items: space-between;
	}
}

.m-relprods {
	@include media('>=tablet-md') {
		grid-row-gap: 7rem;
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	@include media('>=phone-md', '<tablet-md') {
		grid-template-columns: 1fr 1fr;
		@include msgrid(12, 2, 2rem, 2rem);
	}

	mini-product {
		img {
			@include media('>=phone-md', '<laptop') {
				max-height: 21vw;
			    width: auto;
			    margin: 0 auto;
			}
		}
	}
}

.m-product {
	&__banner {
		display: block;
		width: 100%;
		text-align: center;
		padding: 2rem;
		background-color: $c-brand-blue;

		&,* {
			color: #fff;
			font-size: 1.7rem;
		}
		a {
			font-weight: 600;

			&:hover {
				color: #fff;
				text-decoration: underline;
			}
		}
	}
	&__crumbs {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 2rem;

		&\:link,
		&\:final {
			color: $c-gray-l;
			font-size: 1.2rem;

			@include media('<tablet-sm') {
				display: inline-block;
				padding: .5rem 0;
			}

			@include media('>=tablet-sm') {
				font-size: 1.6rem;
			}
		}
		&\:link {
			&::after {
				content: '/';
				margin: 0 0.5rem;
			}
		}
		&\:final {
			font-weight: 600;
		}
	}
	&__atf {
		position: relative !important;
		z-index: 99;

		& > .l-wrapper {
			padding-top: 2rem;

			@include media('>=tablet-lg') {
				padding-top: 6rem;
			}
		}

		&\:grid {
			width: 100%;
			
			@include media('>=tablet-lg') {
				display: flex;
				align-items: flex-start;
				justify-content: center;
			}
		}
	}
	&.-layout-2023 {
		.m-product__atf {
			padding-bottom: 8rem;
		}
	}
	&__gallery {

		@include media('<phone-lg') {
			margin: 0 auto 3rem;
			max-width: 42rem;
		}

		@include media('>=phone-lg', '<tablet-lg') {
			float: right;
			position: sticky;
    		top: 2rem;
		}

		&--full {
			@include media('>=phone-lg', '<tablet-lg') {
				width: 46%;
			}

			@include media('>=tablet-lg') {
				width: 40%;
			}
		}

		&--narrow {
			width: 30%;
		}

		&__slider {
			display: block;
			position: relative;

			&:hover {
				.m-prodslider__nav {
					opacity: 1;
				}
			}

			.m-prodslider__nav {
				height: 0;
				opacity: 0;
				transition: all .3s ease-in-out;

				button {
					position: absolute;
					background: none;
				    border: 0;
				    padding: 0;
				    font-size: 0;
				    top: calc(50% - 3rem);
				    outline: 0;
				    background: #000;
				    border-radius: 1.5rem;
				    cursor: pointer;

				    polygon {
				    	fill: #fff;
				    }

				    &:nth-child(1) {
				    	left: 0;
				    }

				    &:nth-child(2) {
				    	right: 0;
				    }
				}
			}

			.m-slide {
				/*height: 0;
				overflow: hidden;
				opacity: 0;
				transition: opacity 0ms ease-in-out;*/

				.m-zoom-image {
					background-size: contain !important;
					background-repeat: no-repeat;
					@include media('<tablet-sm') {
						background-image: none !important;
					}
				}

				&:not(:hover) {
					.m-zoom-image {
						background-position: center top !important;
					}
				}

				&:hover {
					.m-zoom-image {
						background-size: 200% !important;
					}

					picture {
						@include media('>=tablet-sm') {
							opacity: 0;
						}
					}
				}

				picture {
					display: block;
					cursor: pointer;
				}
			}
		}

		&__thumbs {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			gap: 1rem;

			.m-slide {
				opacity: .5;
				transition: all .3s ease-in-out;
				cursor: pointer;
				width: calc(25% - 1rem);
				padding: 0.5rem;

				@include media('<phone-lg', '>phone-md') {
					width: calc(20% - 1rem);
				}

				@include media('<=phone-md', '>phone-sm') {
					width: calc(33% - 1rem);
				}
				@include media('<=phone-sm') {
					width: calc(50% - 1rem);
				}

				&.active {
					opacity: 1;
				}

				&:hover {
					opacity: 1;
				}
			}
		}
	}
	&__about {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;

		@include media('>=phone-lg', '<tablet-lg') {
			width: 50%;
			float: left;
		}

		@include media('>=tablet-lg') {
			position: sticky;
			padding: 0 2rem;

			&--full {
				width: 40%;
			}

			&--narrow {
				width: 50%;
			}
		}

		&\:title {
			font-weight: 100;
			display: block;
			margin-bottom: 2.5rem;
			width: 100%;

			sup {
				font-size: 45%;
				top: -30px;
			}

			@include media('>=tablet-sm') {
				//font-size: 5.6rem;
			}
		}
		&\:reviews {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 3rem;
			width: 100%;
			padding-bottom: 3rem;
			border-bottom: 1px solid $c-gray-xl;

			&:hover {
				border-bottom: 1px solid $c-gray-xl;
			}

			@include media('<tablet-sm') {
				flex-direction: column;
			}

			& > span {
				display: block;
				color: $c-brand-orange;
				line-height: 1;
				position: relative;
				top: 1px;
				font-weight: 600;

				@include media('>=tablet-sm') {
					margin-left: 2.5rem;
				}
			}
		}
		&\:description {
			width: 55%;
			font-size: 1.6rem;
			color: #000;

			&--full {
				width: 100%;
			}
			&--narrow {
				width: 55%;
			}

			&--variable {
				font-size: 1.6rem;
				color: #000;
				padding-top: 2.5rem;
				border-top: 1px solid $c-gray-xl;
				width: 100%;

				&--empty {
					border: 0;
					padding-top: 0;
				}
			}

			@include media('<tablet-lg') {
				width: 100%;
			}

			p, ul, ol {
				&:not(:last-child) {
					margin-bottom: 1.5rem;
				}
			}
			ul {
				list-style-type: disc;
				padding-left: 3rem;
			}
			li {
				&:not(:last-child) {
					margin-bottom: 1rem;
				}
			}

			&:not(:last-child) {
				margin-bottom: 1.5rem;
			}

			img {
				margin-top: 1rem;
				margin-bottom: 1rem;
			}

			img:not(:last-child) {
				margin-right: 1rem;
			}
		}
		&\:ingredients {
			display: block;
			width: 45%;
			padding-left: 1.5rem;

			@include media('<tablet-lg') {
				width: 100%;
				padding-left: 0;
				margin-top: 3rem;
			}

			 > h3 {
				font-size: 3.4rem;
				display: block;
				width: 100%;
				text-align: center;
			}

			ingredient-box {
				margin-bottom: 2rem;
			}
		}
	}
	&__tags {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		border-top: 1px solid $c-gray-m;
		border-bottom: 1px solid $c-gray-m;
		padding: 1.5rem 0;
		font-size: 1.5rem;
		color: $c-gray-md;
		font-weight: 500;
		margin: 1.5rem 0 2rem;
		width: 100%;

		@include media('>=tablet-sm') {
			padding: 0.5rem 2rem;
		}
	}
	&__tag {
		&:not(:last-child) {
			&::after {
				content: '•';
				padding: 0 1.2rem;
			}
		}
	}
	&__purchase {
		@include media('>=phone-lg', '<tablet-lg') {
			width: 50%;
			float: left;
			padding-top: 4rem;
			clear: left;
		}

		@include media('>=tablet-lg') {
			width: 20%;
			position: sticky;
			top: 2rem;
			margin-left: 2rem;
		}
	}
}
star-reviews {
	display: flex;
	align-items: center;
	justify-content: center;

	@include media('<tablet-sm') {
		justify-content: left;
	}
}
.m-stars {
	@include media('<tablet-sm') {
		flex-direction: column;
	}

	&,
	&__stars {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__stars {
		li {
			display: block;
			width: 2.2rem;
			height: 2rem;
			background-image: url('/wp-content/themes/hyalogic/assets/images/m-star--gray.svg');
			background-repeat: no-repeat;
			background-position: 0 center;
			background-size: auto 100%;
		}

		@include media('<tablet-sm') {
			margin-bottom: 1rem;
		}

		@include media('>=tablet-sm') {
			margin-right: .2rem;
		}
	}
	&__reviews {
		line-height: 1;
		position: relative;
		top: 1px;
		font-weight: 600;
	}
	&--summary &__reviews {
		//color: #000;
		font-size: 1.5rem;
		font-weight: 600;

		@include media('<tablet-sm') {
			margin-bottom: 2rem;
		}

		@include media('>=tablet-sm') {
			margin-left: 1rem;
		}
	}
}
.m-star {
	color: $c-brand-orange;
	font-size: 0;
	display: block;
	width: 100%;
	height: 100%;
	background-image: url('/wp-content/themes/hyalogic/assets/images/m-star--orange.svg');
	background-repeat: no-repeat;
	background-position: 0 center;
	background-size: auto 100%;
}
fs-tracker {
	display: block;
	margin-bottom: 2rem;

	& > span {
		display: block;
		width: 100%;
		text-align: center;
		font-size: 1.6rem;
		font-weight: 600;
		color: $c-brand-blue-m;
		margin-bottom: 1rem;
	}

	.bar {
		display: block;
		width: 100%;
		position: relative;
		height: 1.5rem;
		border-radius: 3rem;
		background-color: $c-gray-xxl;
	}
	.icon {
		display: block;
		width: 3.5rem;
		height: 3.5rem;
		background-color: #fff;
		background-size: 60%;
		background-position: center;
		background-repeat: no-repeat;
		border: 1px solid $c-gray-m;
		filter: drop-shadow(0 5px 1rem rgba(0,0,0,0.1));
		z-index: 3;
		position: absolute;
		top: 0;
		border-radius: 100%;
		transform: translateX(-50%) translateY(-25%);
		transition: left 750ms ease-in-out;
	}
	.fill {
		display: block;
		height: 100%;
		border-radius: 3rem;
		@include gradient-blue;
		transition: width 750ms ease-in-out;
	}
}
ingredient-box {
	display: block;
}
.m-igbox {
	$root: &;
	display: block;
	padding: 2rem;
	border-radius: 3px;
	background-color: $c-gray-xxl;
	position: relative;

	@include media('>=tablet-sm') {
		padding: 2rem 3rem;
	}

	&--slim {
		padding: 1.5rem;

		#{$root}__name {
			font-size: 2rem;
		}
		#{$root}__weight {
			font-size: 1.5rem;
		}
	}
	&__name {
		display: block;
		font-size: 2rem;
		font-weight: 600;
		font-family: $font-b;
		margin-bottom: 1.5rem;
		color: $c-gray-d;
		position: relative;
		z-index: 2;

		@include media('>=tablet-sm') {
			font-size: 2.8rem;
		}
	}
	&__description {
		display: block;
		font-size: 1.4rem;
		color: #000;
		margin-bottom: 2rem;
		position: relative;
		z-index: 2;

		@include media('>=phone-lg') {
			min-height: 8rem;
			width: 75%;
		}

		@include media('>=tablet-sm') {
			font-size: 1.8rem;
		}

	}
	&__weight {
		font-size: 1.8rem;
		color: #000;
		font-weight: 600;
		position: relative;
		z-index: 2;

		@include media('>=tablet-sm') {
			font-size: 1.8rem;
		}
	}
	&__image {
		display: block;
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-radius: 3px;
		background-size: 35% auto;
		background-repeat: no-repeat;
		background-position: right top;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}
}
buy-box {
	display: block;
	background-color: $c-gray-xxl;
	border-radius: 3px;
	padding: 2rem;

	&.hidden {
		top: -15rem !important;
	}

	&.sticky {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 99999;
		filter: drop-shadow(0 1rem 1rem rgba(0,0,0,0.1));
		border-radius: 0;
		transition: top 250ms ease-in-out;

		@include media('<tablet-sm') {
			display: none;
		}

		@include media('>=tablet-sm') {
			height: 8rem;
		}

		@include media('>=laptop') {
			height: 11rem;
		}

		.m-flashmsgs {
			position: fixed;
			top: 13rem;
			left: 20%;
			width: 60%;
			z-index: 9999999;
		}
		.m-flashmsg {
			filter: drop-shadow(0 1rem 1rem rgba(0,0,0,0.1));
		}

		.m-bb {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 0;

			@include media('>=tablet-sm') {
				padding: 0 3rem;
			}

			&__options {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&__title,
			&__stock {
				width: auto;
			}

			&__title {
				margin-bottom: 0;
				border-bottom: 0;
				margin-right: 1rem;
				border-right: 1px solid $c-gray-xl;
				padding: 3rem 2rem 3rem 0;

				@include media('<tablet-sm') {
					display: none;
				}
			}

			&__price {
				margin-bottom: 0;
			}

			&__qty {
				margin-bottom: 0;
			}

			&__stock--in-stock {
				display: none;
			}

			&__offline {
				margin-top: 0;
				font-size: 1.8rem;
			}

			buy-box-options {
				border-bottom: 0;
			}
			buy-box-option {
				margin: 0 2rem;
				max-width: 25rem;
				&:last-of-type {
					padding-bottom: 0;
				}
			}

			input[type=radio]+label, input[type=checkbox]+label {
				margin-bottom: 0;
			}
		}
	}

	& + a {
		margin-top: 2rem;
	}
}
buy-box-options {
	display: block;
	border-bottom: 1px solid $c-gray-xl;
}
buy-box-option {
	display: block;

	&:last-of-type {
		padding-bottom: 2rem;
	}
}
.m-bb {
	position: relative;
	@include form-styles;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(255,255,255,0.85);
		opacity: 0;
		transition: opacity 250ms ease-in-out;
		position: absolute;
		z-index: 5;
		pointer-events: none;
	}
	&::after {
		content: '';
		@extend .spinner;
		position: absolute;
		z-index: 6;
		top: calc(50% - 1.5rem);
		left: calc(50% - 1.5rem);
		opacity: 0;
		transition: opacity 250ms ease-in-out;
		pointer-events: none;
	}

	&.loading {
		&::before,
		&::after {
			opacity: 1;
			pointer-events: all;
		}
	}

	&__title {
		display: block;
		width: 100%;
		font-size: 1.8rem;
		text-transform: uppercase;
		font-family: $font-b;
		color: $c-gray-d;
		font-weight: 600;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
		border-bottom: 1px solid $c-gray-xl;
	}
	&__price {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		margin-bottom: 1.5rem;

		&--normal {
			font-size: 2.4rem;
			font-weight: 600;
			color: $c-brand-blue;
			margin-right: 1rem;
			text-decoration: none;

			@include media('>=tablet-sm') {
				font-size: 3rem;
			}
		}
		&--old {
			font-size: 1.8rem;
			font-weight: 600;
			color: $c-brand-red;
			text-decoration: none;
			display: block;
			padding: 0.5rem;
			position: relative;

			&::after {
				content: '';
				width: 100%;
				height: 1px;
				background-color: $c-brand-red;
				position: absolute;
				left: 0;
				top: 50%;
				transform: rotate(-15deg);
			}
		}
	}
	label[for="bb-opt-recurring"] {
		color: $c-brand-blue;
		display: flex !important;
		flex-wrap: wrap;

		span {
			width: 100%;
			font-size: 80%;
			color: $c-brand-orange;
			display: block;
			margin: 5px 0;
		}

		a {
			display: block;
			font-size: 80%;
			color: $c-gray-m;
			margin-bottom: 5px;
			font-style: italic;
			text-decoration: underline;
		}
	}
	.alternate .m-select__parent,
	.m-select__parent {
		width: calc(100% - 2.5rem);
		margin-left: 2.5rem;
		margin-bottom: 0;
	}

	.m-bb__variation {
		display: block;
		width: 100%;
		margin-bottom: 1.5rem;

		.alternate .m-select__parent,
		.m-select__parent {
			margin-left: 0;
			width: 100%;
		}
	}

	&__stock {
		margin-top: 1.5rem;
		font-size: 1.4rem;
		text-transform: uppercase;
		font-weight: 600;
		display: block;
		width: 100%;
		margin-bottom: 2rem;
		padding-left: 1.5rem;
		position: relative;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 6px;
			width: 1rem;
			height: 1rem;
			border-radius: 100%;
			left: 0;
			background-color: #000;
		}

		&--in-stock {
			color: $c-func-success;

			&::before {
				background-color: $c-func-success;
			}
		}
		&--out-of-stock {
			color: $c-brand-red;

			&::before {
				background-color: $c-brand-red;
			}
		}
	}

	&__offline {
		margin-top: 2rem;
		font-size: 1.6rem;

		a {
			font-weight: 600;
		}
	}

	&__qty {
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		margin-bottom: 1.5rem;

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: lighten($c-gray-xl, 2%);
			border: 1px solid $c-gray-xl;
			font-size: 1.8rem;
			font-weight: 600;
			cursor: pointer;
			width: 3rem;
			outline: 0;
			margin: 0;

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}

		&\:decrement {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		input {
			display: block;
			width: 6rem !important;
			font-size: 2rem !important;
			border-left: 0 !important;
			border-right: 0 !important;
			border-top: 1px solid $c-gray-xl !important;
			border-bottom: 1px solid $c-gray-xl !important;
			color: #000;
			padding: 0.5rem;
			text-align: center;
			font-weight: 600;
			transition: border-color 250ms ease-in-out;
			margin: 0 !important;

			&.invalid {
				border-color: $c-brand-red;
			}
		}
		&\:increment {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}

	}

	footer {
		display: flex;
		align-items: center;
		justify-content: center;

		input[type=submit] {
			@extend .m-button;

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}
	}
}
.m-notices {
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		min-height: 4rem;
		background: $c-brand-orange;
		padding: 0.5rem 1rem;
		color: #fff;
		font-weight: 600;
		font-size: 1.6rem;

		&.error {
			background-color: $c-brand-red;
		}

		&.success {
			background-color: $c-brand-blue;
		}
	}
}
body > .m-notice {
	a {
		color: #fff;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		span {
			display: block;
			width: 100%;
		}
		&::after {
			content: '';
			display: block;
			height: 1px;
			background-color: #fff;
			transition: width 250ms ease-in-out;
			width: 0;
		}
		&:hover::after {
			width: 100%;
		}
	}
}
.m-notice {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 4rem;
	background: $c-brand-orange;
	padding: 0.5rem 1rem;

	&--error {
		background-color: $c-brand-red;
	}

	&--success {
		background-color: $c-brand-blue;
	}

	&__text {
		color: #fff;
		font-size: 1.2rem;
		text-align: center;

		@include media('>=tablet-sm') {
			font-size: 1.6rem;
		}
	}
}
.woocommerce-success,
.woocommerce-warning,
.woocommerce-error {
	display: block;
	width: 100%;
	height: auto;

	li {
		display: block;
		width: 100%;
		padding: 5px 1rem;
		color: #fff;
		font-weight: 500;
		font-size: 1.4rem;
		margin-bottom: 1rem;
		border-radius: 3px;

		a {
			color: #fff;
			text-decoration: underline;
			font-weight: 600;
			font-style: italic;
		}
	}
}
.woocommerce-success li {
	background-color: $c-brand-blue;
}
.woocommerce-warning li {
	background-color: $c-brand-orange;
}
.woocommerce-error li {
	background-color: $c-brand-red;
}
.m-flashmsgs {
	display: block;
	width: 100%;
	height: auto;

	.error,
	.notice,
	.success {
		display: block;
		width: 100%;
		padding: 5px 1rem;
		color: #fff;
		font-weight: 500;
		font-size: 1.4rem;
		margin-bottom: 1rem;
		border-radius: 3px;

		a {
			color: #fff;
			text-decoration: underline;
			font-weight: 600;
			font-style: italic;
		}
	}
	.error {
		background-color: $c-brand-red;
	}
	.notice {
		background-color: $c-brand-orange;
	}
	.success {
		background-color: $c-brand-blue;
	}
}
.m-flashmsg {
	&--error,
	&--warning,
	&--success {
		display: block;
		width: 100%;
		padding: 5px 1rem;
		color: #fff;
		font-weight: 500;
		font-size: 1.4rem;
		margin-bottom: 1rem;
		border-radius: 3px;

		a {
			color: #fff;
			text-decoration: underline;
			font-weight: 600;
			font-style: italic;
		}
	}
	&--error {
		background-color: $c-brand-red !important;
	}
	&--warning {
		background-color: $c-brand-orange !important;
	}
	&--success {
		background-color: $c-brand-blue !important;
	}
}
defer-image {
	picture {
		opacity: 0;
		transition: opacity 500ms ease-in-out;
	}
	img {
		display: block;
		margin: 0 auto;
	}
}
.m-prod-grid-section {
	.l-col--70 {
		@include media('<tablet-lg') {
			width: 100% !important;
			padding-bottom: 5rem;
		}
	}
	.m-sidebar {
		@include media('<tablet-lg') {
			width: 100% !important;
			border-left: 0;
    		padding-left: 0;
		}
	}
}
.m-grid-title-bar {
	display: flex;
    width: 100%;
    margin-bottom: 2rem;

    @include media('<tablet-lg') {
    	flex-wrap: wrap;
    }

    &__right {
		display: inline-flex;
		align-items: center;
		margin-bottom: 2.5rem;

		@include media('<phone-md') {
	    	flex-wrap: wrap;
	    }

	    @include media('<tablet-lg') {
	    	width: 100%;
	    	justify-content: space-between;
	    }
    }

    .m-prod-count {
		white-space: nowrap;
		padding-right: 3rem;

		@include media('<phone-md') {
			width: 100%;
			padding-bottom: 2rem;
		}
    }

    .m-prod-sort {
    	display: inline-flex;
    	align-items: center;

		span {
			white-space: nowrap;
			padding-right: 1.2rem;
		}
		label {
			font-weight: 700;
			display: inline-flex;
    		align-items: center;
		}
		.m-select__parent {
			min-width: 17rem;

			.m-select {
				padding-right: 3rem;
			}
		}
    }
}
.m-sidebar {

	@include media('>=tablet-sm') {
		border-left: .1rem solid darken($c-gray-l, 5%);
		padding-left: 5%;
	}

	&__title {
		font-family: $font-b;
		border-bottom: .1rem solid $c-gray-m;
		font-size: 1.8rem;
		font-weight: bold;
		padding: 2rem 0;
		margin-bottom: 2rem;
	}
}
.m-sidebar-nav {
	padding-bottom: 3rem;

	@include media('>=tablet-sm') {
		padding-bottom: 7rem;
	}

	ul {
		a,
		span {
			color: #000;
			font-size: 1.8rem;
			display: block;
			padding: 1rem 0;
		}

		span {
			color: $c-brand-blue;
		}
	}
}
.m-account {
	& > .l-wrapper {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
	&__dashboard,
	&__subscriptions {
		& > h2 {
			display: block;
			width: 100%;
			margin-bottom: 2rem;
			font-size: 6rem;
			font-weight: 400;
			font-family: $font-s;
		}
		& > footer {
			margin-top: 2rem;
		}
	}
	&__nav {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		flex-wrap: wrap;
		position: relative;
		

		&\:wrapper {
			width: 25%;
			position: sticky;
			top: 2rem;
			z-index: 99;

			@media screen and (max-width: $brk-laptop) {
				width: 100%;
				top: 0;

				&.start {
					&::before {
						opacity: 0;
					}
					&::after {
						opacity: 1;
					}
				}
				&.end {
					&::before {
						opacity: 1;
					}
					&::after {
						opacity: 0;
					}
				}

				&::before,
				&::after {
					content: '';
					width: 3rem;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					z-index: 5;
					pointer-events: none;
					transition: opacity 250ms ease-in-out;
				}
				&::before {
					left: 0;
					background: linear-gradient(to left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
				}
				&::after {
					right: 0;
					background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
				}
			}
		}

		@media screen and (max-width: $brk-laptop) {
			flex-wrap: initial;
			justify-content: flex-start;
			overflow: hidden;
			z-index: 1;
		}

		.ps__rail-x {
			z-index: 99;
		}

		&\:item {
			display: flex;
			width: 60%;
			align-items: center;
			justify-content: center;
			padding: 1.5rem;
			background-color: #fff;
			border: 1px solid $c-gray-xxl;
			transition: background-color 250ms ease-in-out, color 250ms ease-in-out, border-color 250ms ease-in-out;
			position: relative;
			white-space: nowrap;

			@media screen and (max-width: $brk-laptop) {
				width: auto;
				min-width: 22%;
			}

			@media screen and (max-width: $brk-tab) {
				width: auto;
				min-width: 28%;
			}

			@media screen and (max-width: $brk-ph-md) {
				width: auto;
				min-width: 40%;
			}
			@media screen and (max-width: $brk-ph-sm) {
				width: auto;
				min-width: 55%;
			}

			&:hover {
				border: 1px solid $c-gray-xl;
				background-color: $c-gray-xxl;
				z-index: 4;
			}

			&:not(:first-of-type) {
				margin-top: -1px;

				@media screen and (max-width: $brk-laptop) {
					margin-top: 0;
					margin-left: -1px;
				}
			}

			&--active {
				width: 80%;
				background-color: $c-brand-blue-l;
				color: #fff;
				border: 1px solid $c-brand-blue-l;
				z-index: 5;

				@media screen and (max-width: $brk-laptop) {
					width: auto;
				}

				&:hover {
					border: 1px solid $c-brand-blue;
					background-color: $c-brand-blue-l;
					color: #fff;
				}
			}
		}
	}
	&__content {
		width: 75%;
		padding: 0 3rem;

		.m-notices span {
			&:last-child {
				margin-bottom: 3rem;
			}
		}

		@media screen and (max-width: $brk-laptop) {
			width: 100%;
			padding: 0;
			margin-top: 3rem;
		}
	}
	&__tab {
		width: 100%;
	}
	&__orders {
		width: 100%;
	}
	&__order {
		display: block;
		width: 100%;
		background-color: #fff;
		
		&:not(:last-of-type) {
			margin-bottom: 5rem;
		}
		header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border: 1px solid $c-gray-xl;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			background-color: $c-gray-xxl;

			@media screen and (max-width: $brk-tab) {
				flex-wrap: wrap;
			}

			dl {
				flex: 1;
				padding: 1.5rem 2rem;

				@media screen and (max-width: $brk-tab) {
					flex: auto;

					&:last-of-type,
					&:nth-last-child(2) {
						border-top: 1px solid $c-gray-xl;
						width: 50%;
					}
					&:nth-last-child(3) {
						border-right: 0 !important;
					}
				}

				&:not(:last-of-type) {
					border-right: 1px solid $c-gray-xl;
				}

				@media screen and (max-width: $brk-ph-md) {
					width: 100%;

					&:first-of-type,
					&:nth-child(2) {
						border-right: 0 !important;
						border-bottom: 1px solid $c-gray-xl;
					}
				}

				dt {
					text-transform: uppercase;
					font-size: 1.4rem;
					line-height: 1.1;
					font-weight: 600;
					margin-bottom: 0.5rem;
				}
				dd {
					font-size: 1.6rem;
					font-weight: 400;
					white-space: nowrap;
				}

				&.status dd {
					text-transform: uppercase;
				}
			}
		}
		&\:content {
			display: block;
			width: 100%;
			border-left: 1px solid $c-gray-xl;
			border-right: 1px solid $c-gray-xl;
			border-top: 0;

			&__product {
				padding: 1.5rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid $c-gray-xl;
				flex-wrap: wrap;

				&\:about {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					flex-wrap: wrap;

					@include media('>=phone-md') {
						width: 100%;
					}
					@include media('<phone-md') {
						justify-content: center;
					}

					img {
						max-width: 15rem;

						@include media('>=phone-md') {
							max-width: 9rem;
						}

						@include media('<phone-md') {
							width: 50%;
						}
					}

					div {
						padding-left: 2rem;

						@include media('<phone-md') {
							width: 100%;
							margin-top: 1rem;
						}

						h3 {
							font-size: 3.2rem;
							font-weight: 400;
							margin-bottom: 0;
							color: $c-gray-d;
							@include media('<phone-md') {
								text-align: center;
							}
						}

						span {
							display: block;
							margin-top: 5px;
						}
					}
				}
				&\:price {
					margin-top: 2rem;
					font-size: 1.8rem;
					font-weight: 600;
					color: $c-brand-blue;
					width: 100%;
					text-align: center;
					padding: 2rem 1rem 1rem;
					border-top: 1px solid $c-gray-xxl;
				}
			}
		}
		footer {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 1.5rem;
			border: 1px solid $c-gray-xl;
			border-top: 0;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			background-color: lighten($c-gray-xxl, 3%);
		}
	}
	&__subscriptions,
	.woocommerce_account_subscriptions {
		h2 {
			margin-top: 4rem;
			display: block;
			width: 100%;
			margin-bottom: 2rem;
			font-size: 6rem;
			font-weight: 400;
			font-family: $font-s;
		}
		table {
			width: 100%;
			border-collapse: collapse;

			th,td {
				border: 1px solid $c-gray-xl;
				padding: 1rem;
				vertical-align: middle;
			}
			th {
				background-color: $c-gray-xxl;
				font-weight: 600;
				text-transform: uppercase;
			}
			.subscription-id {
				text-align: center;
			}
			.woocommerce-button.view {
				@extend .m-button;
				@extend .m-button--white;
				@extend .m-button--small;
			}

			.button.cancel{
				@extend .m-button;
				@extend .m-button--red;
				@extend .m-button--small;
				display: inline-block;

				@media screen and (max-width: 500px) {
					width: 100%;
					display: block;
				}
			}
			.button.change_payment_method {
				@extend .m-button;
				@extend .m-button--blue;
				@extend .m-button--small;
				display: inline-block;
				margin-left: 0.5rem;

				@media screen and (max-width: 500px) {
					width: 100%;
					display: block;
					margin-left: 0;
					margin-top: 1rem;
				}
			}

			&.subscription_details {
				td:first-of-type {
					font-weight: 600;
				}
			}

			@media screen and (max-width: 600px) {
				display: block;
				thead {
					display: none;
				}
				tbody,
				tr {
					display: block;
					width: 100%;
					margin-bottom: 3rem;
				}
				td {
					display: block;
					width: 100%;
					padding-left: calc(50% + 1rem);
					position: relative;

					&:not(:first-of-type) {
						border-top: none;
					}

					&::before {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						width: calc(50% + 1px);
						background-color: $c-gray-xxl;
						font-weight: 600;
						text-transform: uppercase;
						border-right: 1px solid $c-gray-xl;
					}
					&.subscription-id {
						text-align: left;
					}
					&.subscription-id::before {
						content: 'Subscription';
					}
					&.subscription-status::before,
					&.order-status::before {
						content: 'Status';
					}
					&.subscription-next-payment::before {
						content: 'Next Payment';
					}
					&.subscription-total::before {
						content: 'Total';
					}
					&.product-name::before {
						content: 'Product';
					}
					&.product-total::before,
					&.order-total::before {
						content: 'Total';
					}
					&.order-number::before {
						content: 'Order';
					}
					&.order-date::before {
						content: 'Date';
					}
					&.subscription-actions,
					&.order-actions {
						display: flex;
						justify-content: center;
						padding-left: 0;
					}
				}

				&.subscription_details {
					tbody {
						display: block;
						width: 100%;
					}
					tr {
						margin-bottom: 0;
						width: 100%;
						display: flex;
						flex-wrap: wrap;

						&:not(:first-of-type) {
							td {
								border-top: 0;
							}
						}
						&:not(:last-of-type) {
							td:first-of-type {
								border-right: 0;
							}
						}
						&:first-of-type {
							td {
								border-top: 1px solid $c-gray-xl;
							}
						}
						&:last-of-type {
							td {
								width: 100%;

								&:last-of-type {
									display: flex;
									justify-content: center;
									flex-wrap: wrap;
								}
							}
						}
					}
					td {
						width: 50%;
						padding: 1rem;

						&:first-of-type {
							background-color: $c-gray-xxl;
							font-weight: 600;
							text-transform: uppercase;
						}
					}
				}
				&.order_details {
					tbody,
					tbody tr:last-of-type {
						margin-bottom: 0;
					}
					tfoot {
						display: block;
						width: 100%;
						tr {
							margin-bottom: 0;
							width: 100%;
							display: flex;
							flex-wrap: wrap;

							td {
								border-top: 0;
							}
						}
						th {
							background-color: $c-gray-xxl;
							font-weight: 600;
							text-transform: uppercase;
							width: 50%;
							border-right: 0;
							border-top: 0;

						}
						td {
							width: 50%;
							padding: 1rem;
						}
					}
				}
			}
		}
		.wc-item-meta {
			li {
				display: flex;
				strong {
					margin-right: 5px;
				}
			}
		}
	}
	&__addresses {
		h2 {
			display: block;
			width: 100%;
			margin-bottom: 2rem;
			font-size: 6rem;
			font-family: $font-s;
			font-weight: 400;
		}
		& > .l-row > .l-col {
			&:first-of-type {
				padding-right: 1.5rem;
				@media screen and (max-width: $brk-tab) {
					padding-right: 0;
					margin-bottom: 2.5rem;
				}
			}
			&:last-of-type {
				padding-left: 1.5rem;
				@media screen and (max-width: $brk-tab) {
					padding-left: 0;
				}
			}
		}

		.m-address {
			position: relative;
			display: block;
			width: 100%;
			border: 1px solid $c-gray-xl !important;
			border-radius: 0 !important;
			a.m-button {
				position: absolute;
				top: 1rem;
				right: 1rem;
			}
		}
	}
	.m-accountfields,
	.m-addressfields {
		form {
			padding: 2.5rem;
			background-color: #fff;
			border: 1px solid $c-gray-xl;
			border-radius: 5px;

			.m-select {
				border-radius: 0;
				border-left: 0;
				border-right: 0;
				border-top: 0;
				padding-left: 0;
				padding-right: 0;
				border-color: $c-gray-xl;

				&:focus {
					border-color: $c-gray-md;
				}
			}
		}
		.l-col--2 {
			&:first-child {
				padding-right: 2rem;

				@media screen and (max-width: $brk-tab) {
					padding-right: 0;
				}
			}
			&:last-child {
				padding-left: 2rem;

				@media screen and (max-width: $brk-tab) {
					padding-left: 0;
				}
			}
		}
		label {
			font-weight: 700;
		}
		input:not([type="submit"]),
		.m-select__parent {
			margin-bottom: 4rem;
			border-radius: 0 !important;

			&.invalid {
				border-color: $c-brand-red !important;
			}
		}
		select {
			&.invalid {
				border-color: $c-brand-red !important;
			}
		}
	}
	&__payments {
		& > h2 {
			display: block;
			width: 100%;
			margin-bottom: 2rem;
			font-size: 6rem;
			font-family: $font-s;
			font-weight: 400;
		}
		& > footer {
			margin-top: 2rem;
		}
	}
	&__addpayment {
		.woocommerce-paytrace-SavedPaymentMethods-wrapper {
			background-color: transparent !important;
			padding: 0 !important;
			margin-bottom: 0 !important;
		}
		form {
			position: relative;
			@include form-styles;
			
			&::before {
				content: '';
				width: 100%;
				height: 100%;
				background-color: rgba(255,255,255,0.8);
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 5px;
				opacity: 0;
				pointer-events: none;
				transition: opacity 250ms ease-in-out;
				z-index: 10;
			}

			&::after {
				content: '';
				display: block;
				width: 3.5rem;
				height: 3.5rem;
				border: 4px solid rgba(0, 0, 0, 0.2);
				border-left-color: $c-brand-blue;
				border-radius: 50%;
				animation: spinner 800ms linear infinite;
				position: absolute;
				top: 20%;
				left: calc(50% - 1.75rem);
				position: absolute;
				z-index: 9;
				opacity: 0;
				pointer-events: none;
				transition: opacity 250ms ease-in-out;
				z-index: 10;
			}

			&.loading {
				&::before,
				&::after {
					opacity: 1;
					pointer-events: all;
				}
			}

			& > footer {
				margin-top: 2rem;
			}
		}
	}

	.m-accountfields {
		h2 {
			margin-bottom: 2rem;
			font-size: 6rem;
			font-family: $font-s;
			font-weight: 400;
		}
	}
}
.m-savedcards {
	display: block;
	position: relative;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(255,255,255,0.85);
		opacity: 0;
		transition: opacity 250ms ease-in-out;
		position: absolute;
		z-index: 5;
		pointer-events: none;
	}
	&::after {
		content: '';
		@extend .spinner;
		position: absolute;
		z-index: 6;
		top: calc(50% - 1.5rem);
		left: calc(50% - 1.5rem);
		opacity: 0;
		transition: opacity 250ms ease-in-out;
		pointer-events: none;
	}

	&.loading {
		&::before,
		&::after {
			opacity: 1;
			pointer-events: all;
		}
	}
}
.m-savedcard {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	flex-wrap: wrap;
	border: 1px solid $c-gray-xl;
	background-color: #fff;

	&:not(:first-of-type) {
		margin-top: -1px;

		@media screen and (max-width: $brk-tab) {
			margin-top: 2rem;
		}
	}

	dl {
		padding: 1rem 2rem;

		&:not(:last-of-type) {
			border-right: 1px solid $c-gray-xl;
		}

		dt {
			text-transform: uppercase;
			font-size: 1.4rem;
			font-weight: 600;
			margin-bottom: 5px;
		}
		dd {
			font-size: 1.6rem;
			font-weight: 600;
			white-space: nowrap;
		}
		&.card {
			width: 50%;

			@media screen and (max-width: $brk-tab) {
				border-right: 0;
				width: 100%;
			}
			dd {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				max-width: 100%;

				&::before {
					content: '';
					display: block;
					width: 3.5rem;
					height: 3rem;
					margin-right: 1rem;
					@include bg();
				}

				&.mastercard::before,
				&.mc::before {
					background-image: url(/wp-content/themes/hyalogic/assets/images/mastercard.svg);
				}
				&.visa::before {
					background-image: url(/wp-content/themes/hyalogic/assets/images/visa.svg);
				}
				&.amex::before {
					background-image: url(/wp-content/themes/hyalogic/assets/images/amex.svg);
				}
				&.disc::before,
				&.discover::before {
					background-image: url(/wp-content/themes/hyalogic/assets/images/discover.svg);
				}
				&.jcb::before {
					background-image: url(/wp-content/themes/hyalogic/assets/images/jcb.svg);
				}
				&.diners::before {
					background-image: url(/wp-content/themes/hyalogic/assets/images/diners.svg);
				}
			}
		}
		&.expiration {
			width: 20%;

			@media screen and (max-width: $brk-tab) {
				border-top: 1px solid $c-gray-xl;
				width: 50%;
			}
			@media screen and (max-width: $brk-ph-md) {
				width: 100%;
				border-right: 0;
			}
		}

		&.actions {
			width: 30%;

			@media screen and (max-width: $brk-tab) {
				border-top: 1px solid $c-gray-xl;
				width: 50%;
			}
			@media screen and (max-width: $brk-ph-md) {
				width: 100%;
			}
			dt {
				text-align: center;
			}
			dd {
				display: flex;
				align-items: center;
				justify-content: center;

				a {
					margin: 5px;
				}
			}
		}
	}
}

// .m-continuity-hero {
// 	h2 {
// 		@include media('>=tablet-lg') {
// 			font-size: 6rem;
// 		}
// 		br {
// 			@include media('<tablet-sm') {
// 				display: none;
// 			}
// 		}
// 	}

// 	&::after {
// 		content: '';
// 		background: linear-gradient(180deg, rgba(#fff, 0), #E0DDDA) !important;
// 		position: absolute;
// 		top: 50%;
// 		bottom: 0;
// 		left: 0;
// 		right: 0;
// 		z-index: -1;
// 	}

// 	.l-col {
// 		@include media('<tablet-sm') {
// 			margin-bottom: 2rem;
// 		}

// 		&:nth-of-type(1) {
// 			@include media('>=tablet-sm', '<laptop') {
// 				width: 80% !important;
// 				margin-bottom: 6rem;
// 			}
// 		}
// 		&:nth-of-type(2) {
// 			@include media('>=phone-md', '<tablet-sm') {
// 				margin-bottom: -3rem;
// 			}
// 			@include media('>=tablet-sm') {
// 				margin-top: 7rem;
// 			}
// 			@include media('>=tablet-sm', '<laptop') {
// 				width: 47% !important;
// 				padding-left: 2rem;
// 			}
// 		}
// 		&:nth-of-type(3) {
// 			background: linear-gradient(90deg, #F9F6F4, #E5E2E2) !important;
// 			padding: 5rem 6% !important;
// 			border-radius: .5rem !important;
// 			box-shadow: .2rem .2rem .5rem rgba(#000, .08) !important;

// 			@include media('<tablet-sm') {
// 				max-width: 50rem;
//     			margin: 0 auto;
//     		}

// 			@include media('>=tablet-sm', '<laptop') {
// 				width: 47% !important;
// 			}

// 			@include media('>=laptop') {
// 				margin-top: -12rem;
// 			}

// 			h2 {
// 				@include media('<tablet-sm') {
// 					font-size: 5.2rem;
// 				}

// 				span {
// 					font-size: 2.4rem;
// 					display: block;

// 					@include media('>=tablet-sm') {
// 						font-size: 2.9rem;
// 					}
// 				}

// 				br {
// 					@include media('<tablet-sm') {
// 						display: none;
// 					}
// 				}
// 			}
// 		}
// 	}

// 	.m-form {
// 		.gform_footer {
// 			text-align: left;
// 		}
// 		input[type="submit"] {
// 			width: 100%;
// 		}
// 	}

// 	&--stress {
// 		.m-form input[type="submit"] {
// 			background: linear-gradient(90deg, $c-brand-purple-l, $c-brand-purple-m) !important;
// 		}
// 	}

// 	&--sleep {
// 		.m-form input[type="submit"] {
// 			background: linear-gradient(90deg, $c-brand-blue-l, $c-brand-blue) !important;
// 		}
// 	}
// }

.m-product-features-callout {
	display: flex;
	align-items: center;

	@include media('<phone-md') {
		flex-direction: column-reverse;
	}

	@include media('<tablet-sm') {
		justify-content: center;
	    width: 100%;
	}

	.m-product-features {
		transform: skewX(12deg);

		@include media('>=phone-md') {
			width: 28rem;
		}

		@include media('>=tablet-sm') {
			width: 80%;
		}

		li {
			padding: 1rem 3rem;
			background: linear-gradient(135deg, $c-brand-blue-m, $c-brand-orange) !important;
			margin-bottom: 1rem;

			.m-product-features__content {
				transform: skewX(-12deg);
				color: #fff;

				p {
					font-size: 1.2rem;
				}
			}

			h4 {
				margin: 0;

				@include media('>=tablet-lg') {
					font-size: 3.3rem;
				}
			}
		}
	}
	.m-product-image {
		z-index: 2;

		@include media('>=phone-md', '<tablet-sm') {
			width: 28rem;
			margin-left: -6rem;
		}

		@include media('>=tablet-sm') {
			width: 20%;
		}
		
		img {
			@include media('<tablet-sm') {
				max-width: 23rem;
			}
			@include media('>=tablet-sm') {
				margin: 0 -80%;
				width: 300%;
			    max-width: 300%;
			}
			@include media('>=tablet-lg') {
				margin: 0 -10rem;
				width: 330%;
			    max-width: 330%;
			}
		}
	}
}

body .m-hero--home {
	@include media('<phone-md') {
		margin-bottom: -14rem;
	}
	@include media('>=phone-md', '<tablet-sm') {
		padding-bottom: 19rem !important;
    	padding-top: 62vw !important;
    	margin-bottom: -21rem;
	}

	@include media('<tablet-sm') {
		.m-heading__title--white,
		.m-heading__postheader--white {
			color: $c-brand-blue;
		}
	}

	.m-heading__postheader {
		padding-left: 0;
		font-size: 1.8rem;
	}
}
.grecaptcha-badge {
	z-index: -1;
}
.m-products {
	transition: opacity 250ms ease-in-out;
	&.loading {
		opacity: 0;
	}

	&--carousel__slide {
		width: 1%;
	}
}
.slide_parent {
	display: flex;
	align-items: center;
	justify-content: center;
}
.m-giveaway {
	&__header {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		height: 40rem;

		@media screen and (max-width: 660px) {
			height: 30rem;
		}

		h3 {
			font-family: $font-s;
			font-size: 7.8rem;
			text-align: center;
			color: $c-brand-blue;
			display: flex;
    		flex-direction: column;
    		z-index: 2;

			span {
				display: inline-block;
				text-align: center;
				font-family: $font-b;
				color: #fff;
				background-color: $c-brand-orange;
				font-size: 1.8rem;
				text-transform: uppercase;
				padding: 1rem 3.5rem;
				border-radius: 3px;
				font-weight: 600;
				margin: -.5rem auto 0;
    			z-index: -1;
			}
		}

		svg {
			display: block;
			width: 100%;
			height: auto;
			position: absolute;
			left: 0;
			bottom: -1px;

			* {
				fill: #fff;
			}
		}
	}
	&__main,
	&__alt,
	&__final {
		position: relative;
		h1, h2 {
			font-family: $font-s;
			font-size: 7.8rem;
			text-align: center;
			color: $c-brand-orange;
			width: 100%;

			span {
				display: block;
				text-align: center;
				font-family: $font-h;
				color: $c-gray-md;
				font-size: 5.4rem;
				font-weight: 100;
				margin-top: -1rem;
				line-height: 1;

				@media screen and (max-width: 500px) {
					margin-top: -2rem;
				}
			}
		}
		h2 span {
			color: $c-brand-blue-m;
		}
		h3 {
			&.bubble {
				display: block;
				text-align: center;
				font-family: $font-b;
				color: #fff;
				background-color: $c-brand-orange;
				font-size: 1.8rem;
				text-transform: uppercase;
				padding: 1rem 1.5rem;
				border-radius: 3px;
				font-weight: 600;
				margin-top: 3rem;
			}
		}

		& > .l-wrapper {
			position: relative;
			z-index: 20;
			& > p {
				text-align: center;
				width: 60%;
				margin: 0 auto 5rem;
				font-size: 1.7rem;

				@media screen and (max-width: 660px) {
					width: 80%;
				}
			}
			& > span {
				text-align: center;
				width: 60%;
				margin: 5rem auto 2rem;
				font-weight: 600;
				font-size: 1.7rem;

				@media screen and (max-width: 660px) {
					width: 80%;
				}
			}
			& > img {
				width: 70%;
				height: auto;
				margin-top: 2rem;

				@media screen and (max-width: 660px) {
					width: 90%;
				}
			}

			& > ul {
				display: block;
				width: 80%;
				margin-left: 10%;

				@media screen and (max-width: 660px) {
					width: 95%;
					margin-left: 5%;
				}
				@media screen and (max-width: 550px) {
					width: 100%;
					margin-left: 0;
				}

				li {
					display: block;
					width: 100%;
					margin-top: 5rem;
					padding-left: 10rem;
					position: relative;

					@media screen and (max-width: 550px) {
						padding-left: 0;
					}

					&.product {
						padding-bottom: 5rem;

						&:not(:last-child)::after {
							content: '';
							display: block;
							width: 70%;
							left: 6%;
							height: 2px;
							background: linear-gradient(90deg, rgba(204, 227, 250, 0), #CCE3FA 15%, #CCE3FA 60%, rgba(204, 227, 250, 0));
							position: absolute;
							bottom: -1px;

							@media screen and (max-width: 550px) {
								width: 80%;
								left: 10%;
							}
						}
					}

					a {
						@media screen and (max-width: 550px) {
							display: block;
							width: 100%;
						}
					}

					img {
						position: absolute;
						top: calc(50% - 3rem);
						left: 0;
						width: 16rem;
						height: auto;
						transform: translateX(-50%) translateY(-50%);

						@media screen and (max-width: 550px) {
							position: static;
							width: 80%;
							max-width: 40rem;
							margin: 0 auto 3rem;
							transform: none;
							display: block;
						}
					}

					h4 {
						font-weight: 100;
						font-size: 3.3rem;
						padding-left: 4rem;

						sup {
							font-size: 50%;
							top: -15px;
						}

						b {
							font-size: 2rem;
							color: #fff;
							display: inline-flex;
							justify-content: center;
							align-items: center;
							width: 3.2rem;
							height: 3.2rem;
							background-color: $c-brand-blue;
							margin-right: 0.5rem;
							border-radius: 100%;
							position: relative;
							top: -0.5rem;
							margin-left: -4rem;
						}
					}
					p {
						font-size: 1.7rem;
						margin-bottom: 0;
						margin-top: 0.5rem;
						margin-left: 1rem;
					}
				}
			}
		}
		@media screen and (max-width: 870px) {
			.m-plax {
				display: none;
			}
		}
	}

	&__main {
		z-index: 5;
	}
	&__alt {
		z-index: 2;
		background: linear-gradient(to right, #CCE3FA 0%, #fff 100%);

		.l-wrapper {
			padding-bottom: 4rem;
		}

		svg {
			width: 100%;
			height: auto;

			&:first-of-type {
				position: relative;
				top: -8px;
			}
			&:last-of-type {
				position: relative;
				bottom: -5px;
			}
		}
	}

	.frm {
		display: block;
		width: 90%;
		padding: 2.5rem 6rem;
		background-color: $c-brand-blue;
		color: #fff;
		position: relative;
		margin: 6rem auto;

		@media screen and (max-width: 660px) {
			width: 100%;
		}

		@media screen and (max-width: 550px) {
			padding: 2.5rem 3rem;
		}

		& > img {
			position: absolute;
			display: block;
			width: 20rem;
			height: auto;

			@media screen and (max-width: 550px) {
				display: none;
			}

			&:first-of-type {
				left: 0;
				top: 40%;
				transform: translateX(-65%);
			}
			&:last-of-type {
				right: 0;
				top: 10%;
				transform: translateX(65%);
			}
		}

		&::before {
			content: '';
			width: 100%;
			height: 10rem;
			transform: skewY(-4deg);
			position: absolute;
			top: -4rem;
			left: 0;
			z-index: -1;
			background-color: $c-brand-blue; 
		}
		&::after {
			content: '';
			width: 100%;
			height: 10rem;
			transform: skewY(-4deg);
			position: absolute;
			bottom: -4rem;
			left: 0;
			z-index: -1;
			background-color: $c-brand-blue; 
		}

		h4 {
			display: block;
			width: 100%;
			text-align: center;
			font-weight: 600;
			margin-bottom: 3rem;
			color: #fff;
		}

		.validation_error,
		.validation_message {
			display: block;
			width: 100%;
			background-color: $c-brand-red;
			color: #fff;
			text-align: center;
			padding: 1rem 1.5rem;
			margin-bottom: 1.5rem;		
		}
		.validation_message {
			margin-top: 1rem;
		}

		.gform_body {
			.hidden_label .gfield_label {
				display: none !important;
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-between;
			}

			.ginput_complex {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;

				> * {
					width: 49%;

					@media screen and (max-width: 870px) {
						width: 100%;

						&:not(:first-of-type) {
							margin-top: 3rem;
						}
					}
				}

				label {
					display: block;
					font-size: 1.8rem;
					font-weight: 600;
					margin-bottom: 1rem;
				}
			}

			.gfield {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 3rem;

				.gfield_label {
					display: block;
					font-size: 1.8rem;
					font-weight: 600;
					margin-bottom: 1rem;
				}
				.gfield_description:not(.validation_message) {
					margin-top: 0.5rem;
					margin-left: 1rem;
				}

				.ginput_container {
					width: 100%;
				}

				input:not([type=checkbox]) {
					border: 0;
					width: 100%;
					padding: 2rem 1.5rem;
					font-size: 2rem;
					font-family: $font-b;
					color: #000;
					border-radius: 0;
					background-color: #fff;
				}
				.ginput_container_select {
					position: relative;
					&::after {
						content: '';
						width: 0;
						height: 0;
						border-top: 0.6rem solid #000;
						border-left: 0.6rem solid transparent;
						border-right: 0.6rem solid transparent;
						border-bottom: 0rem solid transparent;
						pointer-events: none;
						position: absolute;
						z-index: 2;
						top: 50%;
						-webkit-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
						right: 2rem;
					}
				}
				select {
					border: 0;
					width: 100%;
					padding: 2rem 1.5rem;
					font-size: 2rem;
					font-family: $font-b;
					color: #000;
					appearance: none;
					border-radius: 0;
					background-color: #fff;
				}
			}
			.gfield.half {
				width: 49%;

				@media screen and (max-width: 870px) {
					width: 100%;
				}
			}
			.gfield_checkbox {
				li {
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
				}

				label {
					font-size: 1.7rem;
					font-weight: 600;
					color: #fff;
					display: block;
					margin-left: 0.5rem;

					a {
						color: #fff;
						text-decoration: underline;
					}
				}
				input[type=checkbox] {
				    width: 2rem;
					height: 2rem;
					cursor: pointer;
					margin: 0 1rem 0 0;
					border: 0;
					border-radius: 0;
					background-color: #fff;
					-moz-appearance: none;
					-webkit-appearance: none;
					appearance: none;
					position: relative;

					&:checked::before {
						content: '';
						color: #fff;
						position: absolute;
						top: 1px;
						right: 1px;
						left: 1px;
						bottom: 1px;
						background-color: transparent;
						background-size: contain;
						background-position: center center;
						background-repeat: no-repeat;
						transition: transform 0.25s ease-in-out;
						background-image: url(data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjQ0OCIgaGVpZ2h0PSI0NDgiIHZpZXdCb3g9IjAgMCA0NDggNDQ4Ij4KPHRpdGxlPjwvdGl0bGU+CjxnIGlkPSJpY29tb29uLWlnbm9yZSI+CjwvZz4KPHBhdGggZmlsbD0iIzAwNzFDOSIgZD0iTTQxNy43NSAxNDEuNWMwIDYuMjUtMi41IDEyLjUtNyAxN2wtMjE1IDIxNWMtNC41IDQuNS0xMC43NSA3LTE3IDdzLTEyLjUtMi41LTE3LTdsLTEyNC41LTEyNC41Yy00LjUtNC41LTctMTAuNzUtNy0xN3MyLjUtMTIuNSA3LTE3bDM0LTM0YzQuNS00LjUgMTAuNzUtNyAxNy03czEyLjUgMi41IDE3IDdsNzMuNSA3My43NSAxNjQtMTY0LjI1YzQuNS00LjUgMTAuNzUtNyAxNy03czEyLjUgMi41IDE3IDdsMzQgMzRjNC41IDQuNSA3IDEwLjc1IDcgMTd6Ij48L3BhdGg+Cjwvc3ZnPgo=);
					}
				}
			}
			.ginput_container_checkbox + .gfield_description:not(.validation_message) {
				font-size: 1.7rem;
				font-weight: 300;
				margin-top: 1rem;
				margin-left: 3.4rem;
			}
		}
		.gform_footer {
			display: flex;
			align-items: center;
			justify-content: center;

			input[type=submit],
			button[type=submit] {
				padding: 4px;
				border: 1px solid #fff;
				border-radius: 25px;
				background:transparent;
				outline: none;
				span {
					color: $c-brand-blue;
					font-size: 2.4rem;
					font-weight: 600;
					font-family: $font-h;
					border-radius: 2rem;
					padding: 1rem 3rem;
					cursor: pointer;
					background: #fff;
					display: block;
				}
			}
			.gform_ajax_spinner {
				margin-left: 1rem;
				border-left-color: $c-brand-blue-l;
			}
		}
	}
}
#agreement {
	display: block;
	position: absolute;
	top: 60rem;
	left: 50%;
	transform: translateX(-50%);
	background-color: #3d3d3d;
	color: #fff;
	padding: 3rem;
	border-radius: 1rem;
	width: 65vw;
	z-index: 9;

	@media screen and (max-width: 768px) {
		width: 87vw;
	}

	&.hide {
		display: none;
	}

	a {
		font-size: 3rem;
		font-weight: 600;
		line-height: 1;
		border-radius: 10rem;
		background-color: #000;
		color: #fff;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(50%, -50%);
		padding: 0;
		margin: 0;
		width: 4rem;
		height: 4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	h3 {
		font-size: 1.6rem;
		font-weight: 600;
		margin-bottom: 1.5rem;
	}

	p {
		font-size: 1.5rem;

		b,strong {
			font-weight: 600;
		}

		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}
	}
}
.m-shop-cta {
	margin: 0 0 6rem;

	@include media('>=tablet-lg') {
		margin: 0 0 3rem;
	}

	h3 {
		font-family: $font-h;
		color: #fff;

		@include media('>=tablet-lg') {
			font-size: 4rem;
		}
	}

	.u-circle {
		@include media('<tablet-sm') {
			max-width: 400px;
	    	margin: 0 auto;
	    }
	}

	.m-button {
		display: inline-block;
	}

	.l-col {
		&:nth-child(2) {
			@include media('<tablet-sm') {
			    align-items: center;
			    justify-content: center;
			    text-align: center;
			    padding-bottom: 3rem;
			}
		}
	}

	&::before,
	&::after {
		@include media('>=tablet-sm') {
		    position: absolute;
		    content: '';
		    display: block;
		    z-index: -1;
		    background-repeat: no-repeat;
		    background-size: contain;
		    background-position: center;
		}
	}

	&::before {
		background-image: url(../images/splash-3.png);
		height: 300px;
	    width: 500px;
	    left: -232px;
	    top: 212px;
	}

	&::after {
		background-image: url(../images/splash-2.png);
		height: 600px;
	    width: 600px;
	    right: -232px;
	    top: 200px;

	    @include media('>=tablet-sm') {
	    	height: 500px;
	    	width: 500px;
	    }

	    @include media('>=laptop') {
	    	height: 600px;
	    	width: 600px;
	    }
	}

	.u-angled-bg {
		&:after {
			@include media('>=tablet-lg') {
				height: 70%;
    			top: 46%;
			}
		}
	}

	&__inner {
		padding: 0 2rem;
		z-index: 1;

		@include media('>=tablet-sm') {
			padding: 0 3rem;
		}

		@include media('>=tablet-lg') {
			padding: 0 3rem;
		}
	}
}
.m-blog-section {
	text-align: center;

	h2 {
		margin: 0;
	}

	&__text {
		max-width: 450px;
		margin: 0 auto 3rem;
	}
}
.pum-container {
	transform: skewY(-5deg) translateZ(0);
	-webkit-font-smoothing: subpixel-antialiased;

	@include media('<phone-lg') {
		padding: 3rem !important;
	}

	&::before,
	&::after {
		content: '';
		background-image: url(https://ha.krna.co/wp-content/uploads//2019/08/hexagons.svg);
		background-size: 100% auto;
		background-position: center;
		background-repeat: none;
		width: 140px;
		height: 208px;
		display: block;
		position: absolute;
	}
	&::before {
		left: 0;
		bottom: 4rem;
		transform: translateX(-60%);
	}
	&::after {
		right: 0;
		top: 6rem;
		transform: translateX(60%);
	}

	.pum-title,
	.pum-content,
	.pum-close {
		transform: skewY(5deg) translateZ(0);
	}

	.pum-title {
		font-family: $font-s;
		font-size: 6rem;
	}

	.pum-content p {
		font-size: 1.7rem;
	}

	.gform_wrapper {
		.hidden_label > label {
			display: none !important;
		}

		form {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
		}

		.gform_body {
			width: calc(100% - 15rem);

			@include media('<phone-lg') {
				width: 100%;
			}
		}
		.gform_footer {
			width: 15rem;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@include media('<phone-lg') {
				width: 100%;
				justify-content: center;
			}

			input {
				display: block;
				margin-right: 1rem;
				padding: 1.5rem 2rem;
				color: #fff;
				background-color: $c-brand-orange;
				border-radius: 1.6rem;
				font-size: 1.6rem;
				border: 0;
				font-weight: 600;
				text-align: center;
				cursor: pointer;
			}
		}

		.gform_fields {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-wrap: wrap;

			li {
				width: 48%;

				@include media('<phone-lg') {
					width: 100%;
					margin-bottom: 2rem;
				}
			}
		}

		.validation_error,
		.validation_message {
			color: $c-brand-red;
			padding: 1rem 2rem;
			background-color: #fff;
			margin: 1rem 0;
			font-weight: 600;
			border-radius: 5px;
		}

		input:not([type=submit]) {
			outline: 0;
			border: 0;
			background-color: transparent;
			color: #fff;
			font-size: 1.8rem;
			font-weight: 400;
			border-bottom: 2px solid #fff;
			padding: 1rem;
			width: 100%;
			border-radius: 0;

			&::placeholder {
				color: rgba(255,255,255,0.8);
			}
		}
	}
}
.l-buttons {
	.m-button {
		margin-top: 1.5rem;

		&:not(:first-of-type) {
			margin-left: 2rem;

			@media screen and (max-width: 420px) {
				margin-left: 0;
			}
		}
	}
}