.l-wrapper {
	display: block;
	width: $site-wrapped;
	max-width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	padding: 2rem 0;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	transition: padding 250ms ease-in-out;

	&--wrapped {
		flex-wrap: wrap;
	}
	&--left {
		justify-content: flex-start;
	}
	&--right {
		justify-content: flex-end;
	}
	&--center {
		justify-content: center;
	}
	&--top {
		align-items: flex-start;
	}
	&--bottom {
		align-items: flex-end;
	}
	&--nopadding {
		padding: 0;
	}

	@media screen and (max-width: $site-wrapped + 40px) {
		padding-left: 2rem !important;
		padding-right: 2rem !important;
	}
	
	&--xnarrow {
		width: $site-wrapped-xnarrow;
	}
	&--narrow {
		width: $site-wrapped-narrow;
	}
	&--wide {
		width: $site-wrapped-wide;
	}
	&--full {
		width: 100%;
		padding: 0 !important;
		// overflow-x: hidden;
	}
}
.l-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;

	&--left {
		justify-content: flex-start;
	}
	&--right {
		justify-content: flex-end;
	}
	&--center {
		justify-content: center;
	}
	&--stretch {
		align-items: stretch;
	}
	&--top {
		align-items: flex-start;
	}
	&--bottom {
		align-items: flex-end;
	}
	&--space {
		justify-content: space-between;
	}
}
.l-col {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	position: relative;

	&--top {
		align-items: flex-start;
	}
	&--bottom {
		align-items: flex-end;
	}
	&--center {
		align-items: center;
		justify-content: center;
	}
	&--right {
		justify-content: flex-end;
	}
	&--vstretch {
		align-items: stretch;
	}
	&--1 {
		width: 100%;
	}
	&--1\.5 {
		width: 20%;
	}
	&--5\.6 {
		width: 83.333333333%;
	}
	&--3\.4 {
		width: 75%;
	}
	&--2 {
		width: 50%;
	}
	&--2\.3 {
		width: 66.666666667%;
	}
	&--2\.5 {
		width: 40%;
	}
	&--3 {
		width: 33.333333333%;
	}
	&--3\.5 {
		width: 60%;
	}
	&--4 {
		width: 25%;
	}
	&--4\.5 {
		width: 80%;
	}
	&--5 {
		width: 20%;
	}
	&--6 {
		width: 16.666666667%;
	}
	&--30 {
		width: 30%;
	}
	&--35 {
		width: 35%;
	}
	&--45 {
		width: 45%;
	}
	&--47 {
		width: 47%;
	}
	&--55 {
		width: 55%;
	}
	&--60 {
		width: 60%;
	}
	&--65 {
		width: 65%;
	}
	&--70 {
		width: 70%;
	}

	// &--5\.6,
	// &--3\.4,
	// &--4,
	// &--4\.5,
	// &--5,
	// &--6 {
	// 	@include media('<tablet-lg') {
	// 		width: 100%;

	// 		&:not(:last-child()) {
	// 			margin-bottom: 2rem;
	// 		}
	// 	}
	// }

	@include media('<tablet-sm') {
		width: 100% !important;

		&:not(:last-child()) {
			margin-bottom: 2rem;
		}
	}
}

.l-grid {
	$root: &;
	display: grid;
	grid-column-gap: 2rem;
	grid-row-gap: 4rem;
	width: 100%;

	&--narrow {
		width: 75%;
		margin-left: auto;
		margin-right: auto;
	}

	@include media('>=tablet-sm') {
		grid-row-gap : 7rem;
	}

	&--2 {
		@include media('>=phone-lg') {
			grid-template-columns: 1fr 1fr;
			@include msgrid(12, 2, 2rem, 2rem);
		}
	}
	&--3 {
		@include media('>=phone-md') {
			grid-template-columns: 1fr 1fr;
			@include msgrid(12, 2, 2rem, 2rem);
		}
		@include media('>=tablet-sm') {
			grid-template-columns: 1fr 1fr 1fr;
			@include msgrid(12, 3, 2rem, 2rem);
		}
	}
	&--4 {
		@include media('>=phone-lg') {
			grid-template-columns: 1fr 1fr;
			@include msgrid(12, 2, 2rem, 2rem);
		}

		@include media('>=tablet-lg') {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			@include msgrid(12, 4, 2rem, 2rem);
		}
	}
	&--5 {
		@include media('>=phone-lg') {
			grid-template-columns: 1fr 1fr;
			@include msgrid(12, 2, 2rem, 2rem);
		}

		@include media('>=tablet-sm') {
			grid-template-columns: 1fr 1fr 1fr;
			@include msgrid(12, 3, 2rem, 2rem);
		}

		@include media('>=tablet-lg') {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			@include msgrid(15, 5, 2rem, 2rem);
		}
	}

	&--gap-md {
		grid-column-gap: 4rem;

		@include media('<tablet-sm') {
			grid-row-gap : 7rem;
		}

		&#{$root}--2 {
			@include msgrid(12, 2, 4rem, 4rem);
		}

		&#{$root}--3 {
			@include msgrid(12, 3, 4rem, 4rem);
		}

		&#{$root}--4 {
			@include msgrid(12, 4, 4rem, 4rem);
		}
	}
}

/**
 * Header
 */
body > header {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}