html, body {
	width: 100%;
	height: 100%;
}
html {
	font-size: 62.5%;
}
body {
	box-sizing: border-box;
	font-family: $font-b;
	font-size: 1.4rem;
	color: $c-gray-d;
	overflow-x: hidden;

	@include media('>=tablet-sm') {
		font-size: 1.5rem;
	}
}
*, *:before, *:after {
  box-sizing: inherit;
}

.m-heading {
	&__preheader {
		color: $c-brand-orange;
		letter-spacing: .2rem;
		font-weight: 500;
		display: block;
		margin-bottom: 1.4rem;
		text-transform: uppercase;
		font-size: 2.4rem;

		&--white {
			color: #fff;
		}
		&--orange {
			color: $c-brand-orange;
		}
		&--blue {
			color: $c-brand-blue;
		}
		&--blue-l {
			color: $c-brand-blue-l;
		}
		&--gray {
			color: $c-gray-md;
		}
		&--teal {
			color: $c-brand-teal;
		}
	}
	&__postheader {
		color: $c-brand-orange;
		letter-spacing: .1rem;
		font-weight: 500;
		font-size: 1.3rem;

		&--white {
			color: #fff;
		}
		&--orange {
			color: $c-brand-orange;
		}
		&--blue {
			color: $c-brand-blue;
		}
		&--blue-l {
			color: $c-brand-blue-l;
		}
		&--gray {
			color: $c-gray-md;
		}
		&--teal {
			color: $c-brand-teal;
		}
	}
	&__title {
		display: block;

		&--white {
			color: #fff;
		}
		&--orange {
			color: $c-brand-orange;
		}
		&--blue {
			color: $c-brand-blue;
		}
		&--blue-l {
			color: $c-brand-blue-l;
		}
		&--gray {
			color: $c-gray-md;
		}
		&--teal {
			color: $c-brand-teal;
		}
	}
	&__title--1 {
		font-family: $font-s;
		margin-bottom: -1rem;
	}
	&__title--2 {
		font-weight: 300;
		letter-spacing: -.1rem;
	}
	&--left {
		.m-heading__title--1 {
			@include media('>=laptop') {
				margin-left: -4rem;
			}
		}
		.m-heading__postheader {
			@include media('>=laptop') {
				padding-left: 4rem;
			}
		}
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-h;
	color: $c-brand-blue;
}
h1, h2, h3 {
	margin-bottom: 1rem;

	@include media('>=tablet-sm') {
		margin-bottom: 1.5rem;
	}

	@include media('>=tablet-lg') {
		margin-bottom: 2rem;
	}
}
h1,
.m-heading--xl h1,
.m-heading--xl h2,
.m-heading--xl h3,
.m-heading--xl h4,
.m-heading--xl h5 {
	font-size: 3.8rem;
	line-height: 1.2;

	@include media('>=tablet-sm') {
		font-size: 4.8rem;
	}

	@include media('>=tablet-lg') {
		font-size: 5.4rem;
	}

	*, a {
		line-height: 1;
	}

	.m-heading__preheader {
		font-size: 1.9rem;
	}

	.m-heading__title--1 {
		font-size: 5.8rem;
		line-height: 0.7;
		margin-bottom: 1rem;

		@include media('>=tablet-sm') {
			font-size: 6.8rem;
		}

		@include media('>=tablet-lg') {
			font-size: 7.8rem;
		}
	}
}
h2,
.m-heading--lg h1,
.m-heading--lg h2,
.m-heading--lg h3,
.m-heading--lg h4,
.m-heading--lg h5 {
	font-size: 3.5rem;
	line-height: 1;

	@include media('>=tablet-sm') {
		font-size: 4.2rem;
	}

	@include media('>=tablet-lg') {
		font-size: 4.8rem;
	}

	*, a {
		line-height: 1;
	}

	.m-heading__preheader {
		font-size: 1.7rem;
	}

	.m-heading__title--1 {
		font-size: 4.8rem;
		line-height: 0.7;
		margin-bottom: 1rem;

		@include media('>=tablet-sm') {
			font-size: 5.8rem;
		}

		@include media('>=tablet-lg') {
			font-size: 6.8rem;
		}
	}
}
h3,
.m-heading--md h1,
.m-heading--md h2,
.m-heading--md h3,
.m-heading--md h4,
.m-heading--md h5 {
	font-size: 2.8rem;
	line-height: 1;

	*, a {
		line-height: 1;
	}

	@include media('>=tablet-sm') {
		font-size: 3rem;
	}

	@include media('>=tablet-lg') {
		font-size: 3.6rem;
	}

	.m-heading__preheader {
		font-size: 1.5rem;
	}

	.m-heading__title--1 {
		font-size: 3.8rem;
		line-height: 0.7;
		margin-bottom: 1rem;

		@include media('>=tablet-sm') {
			font-size: 4.8rem;
		}

		@include media('>=tablet-lg') {
			font-size: 5.8rem;
		}
	}
}
h4,
.m-heading--sm h1,
.m-heading--sm h2,
.m-heading--sm h3,
.m-heading--sm h4,
.m-heading--sm h5 {
	font-size: 2.4rem;
	line-height: 1.1;
	margin-bottom: .5rem;

	*, a {
		line-height: 1.2;
	}

	@include media('>=tablet-sm') {
		font-size: 2.7rem;
	}

	@include media('>=tablet-lg') {
		font-size: 3rem;
	}

	.m-heading__preheader {
		font-size: 1.3rem;
		text-transform: uppercase;
	}

	.m-heading__title--1 {
		font-size: 2.8rem;
		line-height: 0.7;
		margin-bottom: 1rem;

		@include media('>=tablet-sm') {
			font-size: 3.8rem;
		}

		@include media('>=tablet-lg') {
			font-size: 4.8rem;
		}
	}
}
h5,
.m-heading--xs h1,
.m-heading--xs h2,
.m-heading--xs h3,
.m-heading--xs h4,
.m-heading--xs h5 {
	font-size: 2rem;
	line-height: 1.2;
	*, a {
		line-height: 1.2;
	}

	@include media('>=tablet-sm') {
		font-size: 2.2rem;
	}

	@include media('>=tablet-lg') {
		font-size: 2.4rem;
	}

	.m-heading__preheader {
		font-size: 1.1rem;
	}

	.m-heading__title--1 {
		font-size: 2.4rem;
		line-height: 0.7;
		margin-bottom: 1rem;

		@include media('>=tablet-sm') {
			font-size: 3.2rem;
		}

		@include media('>=tablet-lg') {
			font-size: 4rem;
		}
	}
}
h6 {
	font-size: 1.6rem;
	line-height: 1.3;
	*, a {
		line-height: 1.3;
	}

	@include media('>=tablet-sm') {
		font-size: 1.7rem;
	}

	@include media('>=tablet-lg') {
		font-size: 1.8rem;
	}
}

p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
	margin-top: 3rem;
}
div, span, p, a, ol, ul, li, dl, dd, dt, em, i, strong, b {
	line-height: 1.4;
}
a {
	text-decoration: none;
	color: $c-brand-blue;
	transition: color 250ms ease-in-out;

	&:hover,
	&:focus {
		outline: none;
		border: 0;
		color: $c-brand-blue;
	}
}
input, textarea {
	outline: none;
	box-shadow: none;
	font-family: $font-b;
	padding: 8px 1rem;
}
input, textarea {
	-webkit-appearance: none;
}
input[type=radio] {
	-webkit-appearance: radio;
}
input[type=checkbox] {
	-webkit-appearance: checkbox;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}
select {
	-webkit-appearance: none;
	appearance: none;
	outline: 0;
	cursor: pointer;
}
sup {
	font-size: 75%;
	position: relative;
	top: -4px;
}
sub {
	font-size: 75%;
	position: relative;
	bottom: -4px;
}
big {
	font-size: 130%;
}
small {
	font-size: 80%;
}
img {
	max-width: 100%;
	height: auto;

	&.alignright {
		float: right;
		margin: 2rem 0 2rem 3rem;
	}

	&.alignleft {
		float: right;
		margin: 2rem 3rem 2rem 0;
	}
}
strong, b {
	font-weight: 700;
}
em, i {
	font-style: italic;
}
p {
	line-height: 1.6;
	margin-bottom: 2rem;
}