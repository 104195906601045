$font-h: 'interstate', arial, sans-serif;
$font-s: 'shelby', arial, sans-serif;
$font-b: 'interstate', arial, sans-serif;

$c-brand-orange:	#FFA457;
$c-brand-blue: 		#0071C9;
$c-brand-blue-m:	#0099E5;
$c-brand-blue-l: 	#71C3FF;
$c-brand-blue-xl:	#CCE3FA;
$c-brand-blue-xl-alt: #F0FAFF;
$c-brand-blue-xxl:	#F7FAFF;
$c-brand-teal:		#55bfbf;

$c-brand-red:		#ef1644;

$c-hyalock-blue:	#223f7f;

$c-func-success: 	#81b91b;
$c-func-warning: 	$c-brand-orange;
$c-func-error: 		$c-brand-red;

$c-gray-d: 			#302d2b;
$c-gray-md:			#605a55;
$c-gray-m: 			#847e78;
$c-gray-l: 			#aaa8a2;
$c-gray-xl: 		#d3d2cd;
$c-gray-xxl: 		#f3f0ee;

$site-wrapped: 1140px;
$site-wrapped-narrow: 1000px;
$site-wrapped-xnarrow: 800px;
$site-wrapped-wide: 1340px;


/** Gradients */
@mixin gradient-orange {
	background: $c-brand-orange;
	background: linear-gradient(to right, $c-brand-orange 0%, lighten($c-brand-orange, 10%) 100%);
}
@mixin gradient-blue {
	background: $c-brand-blue;
	background: linear-gradient(to right, $c-brand-blue 0%, $c-brand-blue-m 100%);
}


/** Responsive Breakpoints */
$brk-laptop: 	1024px;
$brk-menu: 		860px;
$brk-tab: 		768px;
$brk-ph-lrg: 	620px;
$brk-ph-md: 	520px;
$brk-ph-sm: 	375px;

/** Mixins */
@mixin bg {
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% auto;
}
@mixin bg-cover {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
@mixin word-wrap {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	hyphens: auto;
}

/**
 * MS Grid
 */
@mixin msgrid($totalItems, $totalColumns, $rowGap:"", $colGap:"") {
	$i:			1;
	$row:		1;
	$column:	1;

	$colOneLess: $totalColumns - 1;
	$colMax: ($totalColumns * 2) - 1;
	@if $colGap != "" {
		@if $totalColumns == 1 {
			-ms-grid-columns: 1fr;
		}@else {
			-ms-grid-columns: unquote("(1fr #{$colGap})[#{$colOneLess}] 1fr");
		}
	}

	@while $i <= $totalItems {
		*:nth-child(#{$i}) {
			-ms-grid-row: #{$row}; //Row Declaration

    		@if $i % $totalColumns == 0 { //On last item in row
				-ms-grid-column: $column;
				$row: $row + 1; //Set next row
				$column: 1; //Reset column count
			}@else {
				-ms-grid-column: $column;
				@if $colGap != "" { //Col Gap fix
					$column: $column + 2; //Increment column
				}@else {
					$column: $column + 1; //Increment column
				}
			}
			@if $rowGap != "" {
				@media all and (-ms-high-contrast:none) { //Row Gap fix specifically IE11+
					margin-bottom: #{$rowGap};
				}
			}
		}
		$i: $i + 1;
	}
}

.m-button {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.4rem;
	font-weight: 600;
	padding: 1rem 2rem;
	border-radius: 1.6rem;
	transition: all 250ms ease-in-out;
	position: relative;
	cursor: pointer;
	border: 0;
	line-height: 1.2;
	outline: none;

	@include media('>=tablet-sm') {
		font-size: 1.6rem;
		padding: 1.4rem 2.6rem;
	}

	&--small {
		font-size: 1.4rem;
		padding: 1rem 1.6rem;
	}

	&--xsmall {
		font-size: 1.2rem;
		padding: .8rem 1rem;
	}

	span {
		z-index: 3;
		pointer-events: none;
	}

	&::before {
		content: '';
		position: absolute;
		top: -.3rem;
		left: -.3rem;
		right: -.3rem;
		bottom: -.3rem;
		border: .1rem solid #DCDEE0;
		border-radius: 2rem;
	}

	&--white {
		background-color: #fff;
		color: $c-brand-blue !important;
		border: 1px solid $c-brand-blue;

		&::before {
			content: none;
		}

		&:hover {
			color: $c-brand-blue-m !important;
			transform: translateY(-2px);
			filter: drop-shadow(0 8px 1.5rem rgba(0,0,0,0.1));
			border: 1px solid $c-brand-blue;
		}
	}

	&--o-white {
		background: none;
		color: #fff !important;
		border: .2rem solid #fff;

		&::before {
			content: none;
		}

		&:hover {
			color: $c-brand-blue-m !important;
			background: #fff;
			border: .2rem solid #fff;
		}
	}

	&--o-orange {
		background: none;
		color: $c-brand-orange !important;
		border: .2rem solid $c-brand-orange;

		&::before {
			content: none;
		}

		&:hover {
			color: #fff !important;
			background: $c-brand-orange;
			border: .2rem solid $c-brand-orange;
		}
	}

	&--blue {
		background: $c-brand-blue;
		color: #fff !important;

		&:hover {
			color: #fff !important;
			transform: translateY(-2px);
			filter: drop-shadow(0 8px 1.5rem rgba(0,0,0,0.1));
		}
	}

	&--blue-l {
		background: $c-brand-blue-l;
		color: #fff !important;

		&:hover {
			color: #fff !important;
			transform: translateY(-2px);
			filter: drop-shadow(0 8px 1.5rem rgba(0,0,0,0.1));
		}
	}

	&--orange {
		background: $c-brand-orange;
		color: #fff !important;

		&:hover {
			color: #fff !important;
			transform: translateY(-2px);
			filter: drop-shadow(0 8px 1.5rem rgba(0,0,0,0.1));
		}
	}

	&--red {
		background: $c-brand-red;
		color: #fff !important;

		&:hover {
			color: #fff !important;
			transform: translateY(-2px);
			filter: drop-shadow(0 8px 1.5rem rgba(0,0,0,0.1));
		}
	}

	&--teal {
		background: $c-brand-teal;
		color: #fff !important;

		&:hover {
			color: #fff !important;
			transform: translateY(-2px);
			filter: drop-shadow(0 8px 1.5rem rgba(0,0,0,0.1));
		}
	}

	&--arrow {
		&::after {
			content: '';
			display: inline-block;
			width: .6rem;
			height: .6rem;
			transform: rotate(45deg) translate(-1px, -1px);
			border: .3rem solid #fff;
			border-width: 0 .3rem .3rem  0;
			margin: 0 0 0 .5rem;
		}

		&.active {
			&::after {
				border-width: .3rem 0 0 .3rem;
				transform: rotate(45deg) translateY(0px);
			}
		}

		&.m-button--o-orange {
			&::after {
				border-color: $c-brand-orange;
			}

			&:hover {
				&::after {
					border-color: #fff;
				}
			}
		}
	}

	&__icn {
		&--play {
			margin: 0 0 0 1rem;
			position: relative;
			top: .1rem;
		}
	}
}

@mixin form-styles {
	label {
		cursor: pointer;
	}
	input[type=radio],
	input[type=checkbox] {
		display: none;

		& + label {
			display: block;
			width: 100%;
			padding-left: 2.5rem;
			position: relative;
			line-height: 1;
			margin-bottom: 1rem;
			font-size: 1.6rem;

			* {
				line-height: 1;
			}

			&::before,
			&::after {
				content: '';
				display: block;
				border-radius: 100%;
				position: absolute;
				transition: all 250ms ease-in-out;
			}
			&::before {
				width: 1.5rem;
				height: 1.5rem;
				border: 1px solid $c-gray-m;
				background-color: #fff;
				z-index: 1;
				top: 0;
				left: 0;
			}
			&::after {
				width: 0.7rem;
				height: 0.7rem;
				background-color: $c-brand-blue-m;
				top: 4px;
				left: 4px;
				z-index: 3;
				opacity: 0;
				transform: scale(0.8);
			}

			&:focus::before {
				border-color: $c-gray-d;
			}
		}

		&:checked + label {
			font-weight: 600;

			&::before {
				border-color: $c-brand-blue-m;
			}
			&::after {
				opacity: 1;
				transform: scale(1);
			}
		}
	}
	input[type=checkbox] {
		& + label {
			&::before,
			&::after {
				border-radius: 0;
			}
		}
	}

	.payment_methods  {
		input[type=radio],
		input[type=checkbox] {
			& + label {
				&::before {
					top: 4px;
				}
				&::after {
					top: 8px;
				}
			}
		}
	}

	& > p {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	label {
		display: block;
		width: 100%;
		font-weight: 600;
		font-size: 1.4rem;
		margin-bottom: 1rem;
	}
	input:not([type=submit]):not([type=radio]):not([type=checkbox]),
	textarea {
		width: 100%;
		border: 0;
		border-bottom: 1px solid $c-gray-xl;
		font-family: $font-b;
		font-size: 1.6rem;
		padding: 5px 0;
		margin-bottom: 4rem;
		border-radius: 0 !important;

		&::placeholder {
			color: $c-gray-l;
			font-size: 1.4rem;
		}

		&.invalid {
			border-color: $c-brand-red;
		}

		&:focus {
			border-color: $c-gray-d;
		}
	}
	textarea {
		min-height: 10rem;
		resize: vertical;
		width: 100%;
		padding: 1rem;
		border: 1px solid $c-gray-xl;
	}
	.alternate {
		input:not([type=submit]):not([type=radio]):not([type=checkbox]),
		textarea {
			border: 1px solid $c-gray-m;
			font-family: $font-b;
			font-size: 1.6rem;
			padding: 1rem;
			margin-bottom: 4rem;
			border-radius: 0 !important;

			&:placeholder {
				color: $c-gray-l;
				font-size: 1.4rem;
			}

			&.invalid {
				border-color: $c-brand-red;
			}

			&:focus {
				border-color: $c-gray-d;
			}
		}
		.m-select {
			&__parent {
				display: block;
				width: inherit;
				position: relative;
				margin-bottom: 4rem;

				&::after {
					content: '';
					width: 0;
					height: 0;
					border-top: 0.6rem solid $c-gray-d;
					border-left: 0.6rem solid transparent;
					border-right: 0.6rem solid transparent;
					border-bottom: 0rem solid transparent;
					pointer-events: none;
					position: absolute;
					z-index: 2;
					top: 50%;
					transform: translateY(-50%);
					right: 1rem;
				}

				&--multi {
					&::after {
						content: none;
					}

					select {
						min-height: 10rem;
					}
				}

				&.invalid {
					border-color: $c-brand-red;
				}

				&:focus {
					border-color: $c-gray-d;
				}
			}

			display: block;
			width: 100%;
			cursor: pointer;
			font-size: 1.6rem;
			color: #000;
			padding: 1rem 1.5rem 1rem 1rem;
			background-color: #fff;
			font-family: $font-b;
			border: 1px solid $c-gray-xl;
		}
	}
	input[type=submit] {
		@extend .m-button;
		@extend .m-button--orange;
	}

	.no-main-label {
		& > label {
			display: none !important;
		}
	}
	.ginput_complex {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;

		& > * {
			width: 48%;
		}

		label {
			font-size: 1.2rem;
			display: block;
			margin-top: 1rem;
			color: $c-gray-l;
			text-transform: uppercase;
			font-weight: 500;
			padding-left: 1rem;
		}
	}
	.field_sublabel_above .ginput_complex label {
		display: block;
		width: 100%;
		font-weight: 600;
		font-size: 1.4rem;
		margin-top: 0;
		margin-bottom: 1rem;
		text-transform: none;
		padding-left: 0;
		color: $c-gray-d;
	}
	.gform_footer {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		input {
			margin-right: 2rem;
		}
	}

	.validation_error {
		display: block;
		color: #fff;
		background-color: $c-brand-red;
		padding: 5px 1rem;
		margin-bottom: 2rem;
		border-radius: 3px;
		font-size: 1.4rem;
	}
	.gfield_description.validation_message {
		display: block;
		color: $c-brand-red;
		padding: 5px 0;
		margin-top: -3rem;
		margin-bottom: 3rem;
		font-size: 1.4rem;
	}
	select {
		border-radius: 0;
	}
	.m-select {
		&__parent {
			display: block;
			width: 100%;
			position: relative;
			margin-bottom: 4rem;

			&::after {
				content: '';
				width: 0;
				height: 0;
				border-top: 0.6rem solid $c-gray-d;
				border-left: 0.6rem solid transparent;
				border-right: 0.6rem solid transparent;
				border-bottom: 0rem solid transparent;
				pointer-events: none;
				position: absolute;
				z-index: 2;
				top: 50%;
				transform: translateY(-50%);
				right: 1rem;
			}

			&--multi {
				&::after {
					content: none;
				}

				select {
					min-height: 10rem;
				}
			}
		}

		display: block;
		width: 100%;
		cursor: pointer;
		font-size: 1.7rem;
		color: #000;
		padding: 5px;
		background-color: #fff;
		font-family: $font-b;
		border: 0;
		border-bottom: 1px solid $c-gray-xl;

		&.invalid {
			border-color: $c-brand-red;
		}

		&:focus {
			border-color: $c-gray-d;
		}
	}
}
.m-select {
	&__parent {
		display: block;
		width: 100%;
		position: relative;

		&::after {
			content: '';
			width: 0;
			height: 0;
			border-top: 0.6rem solid $c-gray-d;
			border-left: 0.6rem solid transparent;
			border-right: 0.6rem solid transparent;
			border-bottom: 0rem solid transparent;
			pointer-events: none;
			position: absolute;
			z-index: 2;
			top: 50%;
			transform: translateY(-50%);
			right: 1rem;
		}

		&--multi {
			&::after {
				content: none;
			}

			select {
				min-height: 10rem;
			}
		}
	}

	display: block;
	width: 100%;
	cursor: pointer;
	font-size: 1.6rem;
	color: #000;
	padding: 1rem 1.5rem 1rem 1rem;
	background-color: #fff;
	font-family: $font-b;
	border: 1px solid $c-gray-m;

	&.invalid {
		border-color: $c-brand-red;
	}

	&:focus {
		border-color: $c-gray-d;
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.gform_ajax_spinner,
.spinner {
	display: inline-block;
	border: 4px solid rgba(0, 0, 0, 0.2);
	border-left-color: $c-brand-blue-m;
	border-radius: 50%;
	width: 3rem;
	height: 3rem;
	animation: spinner 1.2s linear infinite;
}
.hide {
	display: none !important;
}