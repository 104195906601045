.u-col--border-right {
	border-right: .1rem solid darken($c-gray-l, 5%);
	padding-right: 5%;
}

.u-p-t {
	&--none {
		padding-top: 0 !important;
	}
	&--sm {
		padding-top: 2.5rem !important;

		@include media('>=tablet-sm') {
			padding-top: 5rem !important;
		}
	}
	&--md {
		padding-top: 5rem !important;

		@include media('>=tablet-sm') {
			padding-top: 10rem !important;
		}
	}
	&--lg {
		padding-top: 8rem !important;

		@include media('>=tablet-sm') {
			padding-top: 16rem !important;
		}
	}
	&--xl {
		padding-top: 11rem !important;

		@include media('>=tablet-sm') {
			padding-top: 22rem !important;
		}
	}
}

.u-p-b {
	&--none {
		padding-bottom: 0 !important;
	}
	&--sm {
		padding-bottom: 2.5rem !important;

		@include media('>=tablet-sm') {
			padding-bottom: 5rem !important;
		}
	}
	&--md {
		padding-bottom: 5rem !important;

		@include media('>=tablet-sm') {
			padding-bottom: 10rem !important;
		}
	}
	&--lg {
		padding-bottom: 8rem !important;

		@include media('>=tablet-sm') {
			padding-bottom: 16rem !important;
		}
	}
	&--xl {
		padding-bottom: 11rem !important;

		@include media('>=tablet-sm') {
			padding-bottom: 22rem !important;
		}
	}
}

.u-p-l {
	&--none {
		padding-left: 0 !important;
	}
	&--sm {
		padding-left: 1rem !important;

		@include media('>=tablet-sm') {
			padding-left: 2.5rem !important;
		}
	}
	&--md {
		padding-left: 3rem !important;

		@include media('>=tablet-sm') {
			padding-left: 5rem !important;
		}
	}
	&--lg {
		padding-left: 8rem !important;

		@include media('>=tablet-sm') {
			padding-left: 10rem !important;
		}
	}
	&--xl {
		padding-left: 11rem !important;

		@include media('>=tablet-sm') {
			padding-left: 18rem !important;
		}
	}
}

.u-p-r {
	&--none {
		padding-right: 0 !important;
	}
	&--sm {
		padding-right: 1rem !important;

		@include media('>=tablet-sm') {
			padding-right: 2.5rem !important;
		}
	}
	&--md {
		padding-right: 3rem !important;

		@include media('>=tablet-sm') {
			padding-right: 5rem !important;
		}
	}
	&--lg {
		padding-right: 8rem !important;

		@include media('>=tablet-sm') {
			padding-right: 10rem !important;
		}
	}
	&--xl {
		padding-right: 11rem !important;

		@include media('>=tablet-sm') {
			padding-right: 18rem !important;
		}
	}
}

.u-o-t {
	&--sm {
		@include media('>=tablet-sm') {
			margin-top: -4rem;
		}
	}
	&--md {
		margin-top: -2rem;

		@include media('>=tablet-sm') {
			margin-top: -8rem;
		}
	}
	&--lg {
		margin-top: -4rem;

		@include media('>=tablet-sm') {
			margin-top: -12rem;
		}
	}
	&--xl {
		margin-top: -9rem;

		@include media('>=tablet-sm') {
			margin-top: -16rem;
		}
	}
}

.u-angled-bg {
	position: relative;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background: linear-gradient(135deg, $c-brand-blue, $c-brand-blue-m) !important;
		transform: skew(0, -3deg) translate(-50%, -50%);
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: -1;
	}
}

.u-warning {
	color: $c-func-error;
}

.u-white {
	color: #fff;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: #fff;
	}
}

.u-100 {
	width: 100%;
}

.u-bg-img {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	@include media('>=tablet-sm', '<tablet-lg') {
		background-size: 136% auto;
	    background-position: 67% top;
	    padding-top: 0;
	}

	@include media('<tablet-sm') {
		background-size: 165% auto;
	    background-position: right top;
	    padding-top: 56vw !important;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 95vw;
		background: linear-gradient(0deg, #fff, #fff 30%, rgba(#fff, 0) 60%, rgba(#fff, 0)) !important;

		@include media('>=tablet-lg') {
			display: none;
		}
	}

	&--left {
		@include media('>=tablet-sm', '<tablet-lg') {
		    background-position: 33% top;
		}

		@include media('<tablet-sm') {
		    background-position: left top;
		}
	}

	&--o-gray-xxl {
		&::before {
			height: 100%;

			background: linear-gradient(180deg, rgba(#e9e8ed, 0), rgba(#e9e8ed, 0) 30vw, #e9e8ed 55vw, #e9e8ed) !important;
		}
	}
}

.u-bg-img--top {
	background-position: center top !important;
}

.u-bg-img--right {
	background-position: right top !important;
}

.u-bg {
	&--g-blue-d-angled {
		z-index: 10;
		
		.l-wrapper {
			position: relative;
			color: #fff;

			&::before {
				content: '';
				background: linear-gradient(135deg, $c-brand-blue, $c-brand-blue-m) !important;
				position: absolute;
				transform: skew(0, -5deg);
				top: -5%;
				right: -2rem;
				bottom: -5%;
				left: -2rem;
				z-index: 0;

				@media screen and (max-width: 1100px) {
					left: 0;
					right: 0;
				}

				@include media('>=tablet-sm') {
					top: -8%;
					bottom: -8%;
				}
			}

			.l-row {
				position: relative;
				z-index: 1;

				@include media('>=tablet-sm') {
					padding: 2rem 9%;
				}

				.l-row {
					padding: 0;
				}
			}

			.m-plax {
				img {
					width: 20rem;
					height: 40rem;
					max-width: none;
					max-height: none;
					display: block;
				}

				&--left {
					left: -10rem;
				}

				&--right {
					right: -10rem;
				}
			}
		}

		&.short-angle {
			.l-wrapper {
				&::before {
					top: 9%;
    				bottom: 5%;
				}
			}
		}
	}
	&--l-blue {
		background-color: $c-brand-blue-xl-alt;
	}
}

.u-5-pillars {
	color: $c-brand-orange;
    align-items: center;

    @include media('>=tablet-sm') {
		font-size: 5rem;
		display: flex;
    }

    span {
    	margin-right: .5rem;
		display: inline-block;

		@include media('>=tablet-sm') {
			margin-right: 1rem;
			line-height: .7;
			font-size: 12rem;
			font-weight: 300;
	    }
    }
}

.u-circle-image-collage {
	.m-image {
		position: absolute;

		&:nth-of-type(1) {
			width: 100%;
			position: relative;
			margin-top: 10%;
			z-index: 5;
		}

		&:nth-of-type(2) {
		    width: 46%;
		    left: -7%;
		    top: 73%;
		    z-index: 7;
		}

		&:nth-of-type(3) {
			width: 28%;
		    left: -4%;
		    top: 2%;
			z-index: 3;
		}

		&:nth-of-type(4) {
			width: 31%;
		    left: 75%;
		    top: 73%;
		    z-index: 8;
		}

		&:nth-of-type(5) {
		    width: 18%;
		    left: 83%;
		    top: -3%;
		    z-index: 8;
		}		
	}
}

.u-content-below {
	.l-wrapper {
		z-index: 2;
	}
}

.u-notice {
	border-radius: .5rem;
	padding: 1.4rem 2rem;
    line-height: 1.4;
    background: $c-gray-xxl;
    color: $c-brand-blue;
}

.u-p-col--0 {
	.l-col:not(:last-child) {
		@include media('<tablet-sm') {
			margin-bottom: .2rem;
		}
	}
}


.u-reverse {
	.l-row {
		@include media('<tablet-sm') {
			flex-direction: column-reverse;
		}

		.l-col {
			@include media('<tablet-sm') {
				margin-bottom: 0;
			}
		}
	}
}

.u-circle {
	border-radius: 500px;
}